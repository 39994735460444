import React, { useState, useEffect } from "react";
import "../about_page/about.css";
import Mission1 from "../../../assets/Mission1.png";
import Mission2 from "../../../assets/Mission2.png";
import Mission3 from "../../../assets/Mission3.png";
import AboutBanner from "./vision_banner";
import ContactDiv from "../right_div/contact_div";
const initial_committee_contacts = [
  {
    id: 1,
    image: "dummy.png",
    name: " ",
    company: " ",
  },
];
function Vision() {
  const [state, setstate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [committee_contacts, setcommittee_contacts] = useState(
    initial_committee_contacts
  );

  useEffect(() => {
    const url = new URL(window.api_url + "/about");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {});

  }, []);

  return (
    <section>
      <AboutBanner data={state} />
      <div className="container py-4 vision_container">
        <h1 className="text-center text-3xl color11">{state.heading1}</h1>
        <p className="py-4 text-xl text-left vision_para">{state.text1}</p>
        <div className="row px-8 d-flex justify-content-between">
          <div className="col-lg-3 col-md-6 col-sm-12" id="mission">
            <img src={Mission3} alt="vision" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12" id="mission">
            <img src={Mission2} alt="vision" />
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 rounded-circle"
            id="mission"
          >
            <img src={Mission1} alt="vision" />
          </div>
        </div>
      </div>
      <div className="container py-8 mission_container">
        <h1 className="text-3xl py-2 color11">{state.heading2}</h1>
        <p className="text-xl text-left mission_para">{state.text2}</p>
      </div>
    </section>
  );
}

export default Vision;
