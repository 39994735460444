import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
const mystyle = { overflowX: 'unset' }
const TwitterCard = () => {
  return (
    <div className="twitter_timeline my-2 twitter_loading_box">
      <TwitterTimelineEmbed
        theme="dark"
        noFooter="true"
        sourceType="profile"
        screenName="steel_indian"
        options={{}}
      />
    </div>
  );
};

export default TwitterCard;
