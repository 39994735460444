import React from "react";
import NewsDate from "./date_time/news_date";

const Block1 = (props) => {
  return (
    <React.Fragment>
      <div className="row event_block_row m-3 mb-6 course_container_row">
        <div className="col col-5 text-left steel_technology_img_div">
          <div className="block_img_div">
            <div className="block_img">
              <img
                className="w-100 h-100"
                src="https://education.ec.europa.eu/sites/default/files/styles/eac_ratio_16_9_xl/public/2021-12/Planning%20your%20studies.jpg?h=140710cd&itok=EDqz50YE"
                alt="course_img"
              />
              <div className="course_arrow_icon"></div>
            </div>
          </div>
        </div>

        <div className="col col-7 text-left course_block_right_div">
          <div className="block_right_div">
            <div className="block_right">
              <h3 className=" pl-3 pt-3"> {props.block_detail.heading} </h3>
              <NewsDate data={props.block_detail} />
              <p className="pl-3">{props.block_detail.description}</p>
              <button className="btn btn-primary ml-3 my-4">
                Play Video <i className="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Block1;
