function DateFunction(params) {
    const d = new Date(params.date);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <>
        <p className="text-left fw-bold dateColor ">
          {" "}
          {d.getDate()} {months[d.getMonth()]}, {d.getFullYear()}{" "}
        </p>
      </>
    );
  }

  export default DateFunction;