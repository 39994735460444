import { useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../layouts/wrapper";

const Extranet = () => {
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params);
    console.log(params);
  };
  return (
    <Wrapper getUserData={getUserData}>
      <div className="container text-left">
        <div className="row">
          <div className="col col-12">
            <h1> Members area: Indian Steel Association extranet </h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-8">
            <img src="https://worldsteel.org/wp-content/uploads/Membership_home.jpg" />
            <p>
              The Indian Steel Association extranet a password protected website available
              exclusively to members. It provides details of all our programmes,
              activities and campaigns. </p> <p> It contains a wealth of reports and
              publications unique and useful to the steel industry. If your
              company is a member of the World Steel Association and you would
              like access to the worldsteel extranet please provide your contact
              details by clicking on ‘Request access’ below.
            </p>
          </div>
          <div className="col col-4">
            <Link to={'/ExtranetLogin'} className="btn btn-primary"> Continue to the extranet </Link>
            
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Extranet;
