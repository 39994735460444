import React from "react";

const InsightCard = () => {
  
  const InsightCardData = [
    {
      id:1,
      tittle: "Life cycle thinking",
      description: `To understand the environmental performance of a product, its entire life cycle needs
    to be taken into consideration. A life cycle assessment (LCA) of a steel product looks
    at resources.`
    }
  ]

  return (
    <div className="card insight_card my-2">
      {
        InsightCardData.map((e)=> {
          return (
            <React.Fragment key={e.id}>
              <img
        className="card-img-top"
        src={window.api_storage_url + "/images/home_page/lifecycle.jpg"}
        alt="Card_image_cap"
      />
      <div className="card-body text-left">
        <h4 className="card-title">{e.tittle}</h4>
        <p className="card-text">
         {
          e.description
         }
        </p>
        <a href="#" className="btn btn-primary in_the_news_btn float-right mt-2">
          Read More
        </a>
      </div>
            </React.Fragment>
          )
        })
      }
    
    </div>
  );
}

export default InsightCard;