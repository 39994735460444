import { useLocation } from "react-router-dom";
import RouteComponent from "./routes/route_component";

function App() {
  const location = useLocation();
  const { pathname } = location;
  window.relativeUrl = pathname.split("http://localhost:3000/").toString();
  window.baseUrl = pathname;
  return (
    <>
      <RouteComponent />
    </>
  );
}

export default App;
