import React from "react";
import Wrapper from "../layouts/wrapper";
import PageHeading from "./components/page_heding";
import InnerWrapper from "./components/wrapper_3_9";
import Menulist from "./components/left_div/menu_list";
import CommitteeDetail from "./components/right_div/committee_detail";

const submenu = [
  {
    key: 1,
    menu: "Apex Committee",
    to: "/committees/apexCommittee",
  },
  {
    key: 2,
    menu: "Working Committee",
    to: "/committees/workingCommittee",
  },
  // {
  //   key:3,
  //   menu: "Website Commitee",
  //   to: "/committees/websiteCommittee",
  // },
  {
    key: 4,
    menu: "Other Committees",
    to: "/committees/subCommittee",
    committee_submenu: [
      {
        key: 41,
        menu: "Procurement",
        to: "/committees/procurement",
      },
      {
        key: 42,
        menu: "Communication ",
        to: "/committees/communication",
      },
      {
        key: 43,
        menu: "Raw Materials",
        to: "/committees/rawMaterials",
      },
      {
        key: 44,
        menu: "Regulatory ",
        to: "/committees/regulatory",
      },
      {
        key: 45,
        menu: "Economics",
        to: "/committees/economics",
      },
      {
        key: 46,
        menu: "Environment ",
        to: "/committees/environment",
      },
      {
        key: 47,
        menu: "Health & Safety",
        to: "/committees/healthAndSafety",
      },
      {
        key: 48,
        menu: "Sustainability, Energy & Climate Change",
        to: "/committees/secc",
      },
    ],
  },
  {
    key: 5,
    menu: "Vishakha Committee",
    to: "/committees/vishakhaCommittee",
  },
  {
    key: 6,
    menu: "Affiliate Members",
    to: "/committees/affiliateMembers",
  },
];
const Committee = () => {
  return (
    <Wrapper menu="Committees" submenu={submenu} key={submenu.key}>
      {/* <PageHeading l1="Committees" id="comitee_heading" /> */}
      <InnerWrapper col3={<Col3 />} col9={<Col9 />} />
    </Wrapper>
  );
};

const Col3 = (props) => {
  return (
    <React.Fragment>
      <ul>
        {submenu.map((e) => (
          <Menulist key={e.key} submenu={e} />
        ))}
      </ul>
    </React.Fragment>
  );
};

const Col9 = (props) => {
  return (
    <React.Fragment>
      <CommitteeDetail submenu={submenu} />
    </React.Fragment>
  );
};

export default Committee;
