import React, { useEffect, useState } from "react";
import "./slider.css";

function Banner2() {
  const initial_state = [
    {
      id: 1,
      created_at: "2022-05-20T16:36:24.000000Z",
      updated_at: "2022-05-28T07:44:21.000000Z",
      status: 1,
      date: "2022-05-09",
      heading: "2021 Dubai  steel and Raw Materials Hybrid Conference",
      subheading1: "2021 Dubai  steel",
      subheading2: "and Raw Materials",
      subheading3: "Hybrid Conference",

      image: "../sliders/62bea450c609c.jpg",
      description:
        "Indian Steel Association (ISA) taking over from Bhaskar Chatterjee.",
      url: "bb",
      location: "hh",
      year: null,
      filter: null,
    },
    {
      id: 2,
      created_at: "2022-05-20T16:36:24.000000Z",
      updated_at: "2022-05-28T07:44:21.000000Z",
      status: 1,
      date: "2022-05-09",
      heading: "Website Comittee meeting of ISA",
      subheading1: "website",
      subheading2: "Comitee",
      subheading3: "Meeting of ISA",

      image: "62b2c8925ed61.jpg",
      description:
        "Indian Steel Association (ISA) taking over from Bhaskar Chatterjee.",
      url: "bb",
      location: "hh",
      year: null,
      filter: null,
    },
    {
      id: 3,
      created_at: "2022-05-20T16:36:24.000000Z",
      updated_at: "2022-05-28T07:44:21.000000Z",
      status: 1,
      date: "2022-05-09",
      heading: "Steel shaping the future",
      subheading1: "Steel",
      subheading2: "Shaping",
      subheading3: "the future",

      image: "62b2c79b9168a.jpg",
      description:
        "Indian Steel Association (ISA) taking over from Bhaskar Chatterjee.",
      url: "bb",
      location: "hh",
      year: null,
      filter: null,
    },
  ];

  const [state, setState] = useState(initial_state);
  const [home_page, set_home_page] = useState(initial_state[0]);
  //const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/slider1");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setState(data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <div id="sync1" className="owl-carousel owl-theme">
        {state.map((e) => (
          <BannerBox key={e.id} data={e} />
        ))}
      </div>


      <div className="container home_banner_container">
        <div id="sync2" className="owl-carousel owl-theme">
          {state.map((e) => (
            <BannerBox2 key={e.id} data={e} />
          ))}
        </div>
      </div>
    </>
  );
}
// console.log('banner2');
const BannerBox = (props) => {
  return (
    <section>
      <div className="">
        <div className="container home_banner_container">
          <div className="row px-3 pb-0">
            <div className="col col-12 px-0 banner_image_div">
              <div className="banne_div_img">
                <img
                  className="h-100"
                  src={
                    window.api_storage_url +
                    "/images/sliders/" +
                    props.data.image
                  }
                  alt="home_banner"
                />
              </div>

              {/* <button className="btn btn-outline-light float-left mx-8 mt-4">Hybrid Event</button> */}
              <div id="left_container" className="left_container">
                <div className="top_banner_heading_container text-left">
                  <div className="div1">
                    <h1 className="px-2" id="home_banner_heading">
                      {props.data.subheading1}
                    </h1>
                  </div>
                  {props.data.subheading2 ? (
                    <div className="div2">
                      <h1 className="px-2" id="home_banner_heading">
                        {props.data.subheading2}
                      </h1>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.data.subheading3 ? (
                    <div className="div3">
                      <h1 className="px-2" id="home_banner_heading">
                        {props.data.subheading3}
                      </h1>
                    </div>
                  ) : (
                    ""
                  )}

                  <p className="text-white text-xl py-4 " id="home_banner_para">
                    {props.data.description}
                  </p>
                  <DateFunction date={props.data.date} />
                  <button
                    className="float-left btn btn-light mt-2"
                    id="home_banner_btn"
                  >
                    Read More <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const BannerBox2 = (props) => {
  return (
    <div className="row slider_item_container">
      <div className="col-3">
        <h1 className="section_id"> {props.data.id}</h1>
      </div>
      <div className="col-9">
        <div className=" text-left">
          <div className="text-right">
            <button
              className={
                "bg22 px-3 py-1 text-light section2_slider_btn " +
                props.data.type
              }
            >
              {props.data.type}
            </button>
          </div>
          <h1 className="py-2 section2_heading">{props.data.heading}</h1>
          <DateFunction date={props.data.date} />
        </div>
      </div>
    </div>
  );
};

function DateFunction(params) {
  const d = new Date(params.date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <p className="text-left fw-bold dateColor banner_date">
        {" "}
        {d.getDate()} {months[d.getMonth()]}, {d.getFullYear()}{" "}
      </p>
    </>
  );
}

export default Banner2;
