import React, { useEffect, useState } from "react";

import MainBlock from "../block1";
import MainBlockLoading from "../loading_block1";

const ReportList = (props) => {
  const [state, setstate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(
      window.api_url +
        "/reports?report_type=" +
        props.report_type
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <MainBlockLoading />
      ) : (
        <>
          {state.map((e) => (
            <>
            {!props.FilteredYear
                ? <MainBlock block_detail={e} />
                : <>{e.date.split("-")[0]==props.FilteredYear ? <MainBlock block_detail={e} /> : ''} </> 
              }
              </>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default ReportList;
