import React, { useState } from "react";

import Wrapper from "../layouts/wrapper";
import MediaContact from "./components/media/media_contact";
import MediaPage2 from "./components/media/media_page2";
import InTheNews from "./components/media/in_the_news";
import PressRelease from "./components/media/press_release";
import OurStory from "./components/media/our_story";
import Rendezvous from "./components/media/rendezvous";
const Media = () => {
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params);
  };

  const guestSubmenu = [
    {
      id: 1,
      menu: "Press Release",
      to: "/media/pressRelease",
    },
    {
      id: 2,
      menu: "In The News",
      to: "/media/inTheNews",
    },
    {
      id: 3,
      menu: "Our Stories",
      to: "/media/ourStory",
    },
    {
      id: 4,
      menu: "Rendezvous",
      to: "/media/Rendezvous",
    },
    {
      id: 5,
      menu: "Media Contact",
      to: "/media/contact",
    },
  ];

  const extranetSubmenu = [
    ...guestSubmenu,
    { menu: "*Representation", to: "/media/representation" },
    { menu: "*Resources", to: "media/resource" },
  ];

  return (
    <Wrapper
      getUserData={getUserData}
      menu="Media"
      submenu={UserData.name ? extranetSubmenu : guestSubmenu}
    >
      <React.Fragment>
        {window.relativeUrl === "/media/pressRelease" ? (
          <PressRelease media_type="1" />
        ) : (
          ""
        )}
        {window.relativeUrl === "/media/inTheNews" ? (
          <InTheNews media_type="2" />
        ) : (
          ""
        )}
        {window.relativeUrl === "/media/ourStory" ? (
          <OurStory media_type="3" />
        ) : (
          ""
        )}
        {window.relativeUrl === "/media/Rendezvous" ? (
          <Rendezvous media_type="4" />
        ) : (
          ""
        )}
        {window.relativeUrl === "/media/contact" ? <MediaContact /> : ""}
      </React.Fragment>
    </Wrapper>
  );
};

export default Media;
