import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Loading from "../shared/loading";
import ContactDiv from "./contact_div";
const CommitteeDetail = (props) => {
  const initial_state = [
    {
      id: 1,
      name: "Apex Committee",
      description: `  `,
    },
  ];
  const initial_committee_contacts = [
    {
      id: 1,
      image: "dummy.png",
      name: " ",
      company: " ",
    },
  ];

  const [committee_detail, setcommittee_detail] = useState(initial_state[0]);
  const [committee_contacts, setcommittee_contacts] = useState(
    initial_committee_contacts
  );
  const [isLoading, setIsLoading] = useState(true);

  const href = window.relativeUrl;
  useEffect(() => {
    setIsLoading(true);
    const url = new URL(window.api_url + "/committeeDetail?href=" + href);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setcommittee_detail(data.committee);
        setcommittee_contacts(data.committee_contacts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [href]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="description_box">
          <h1>{committee_detail.name} </h1>

          {href !== "/committees/subCommittee" && (
            <div
              dangerouslySetInnerHTML={{
                __html: committee_detail.long_description,
              }}
            />
          )}

          {href === "/committees/subCommittee" && (
            <div className="container">
              <div className="row member_types my-2">
      
              {props.submenu[2].committee_submenu.map((e) => {
                return <Fun key={e.key} submenu={e} />;
              })}
      </div>
            </div>
          )}
          <div className="row p-0 py-2 px-3">
            {committee_contacts.length === 0 ? (
              ""
            ) : (
              <ContactDiv contact_detail={committee_contacts} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const Fun = (props) => {
  return (
    
    <div className="col col-lg-6 col-md-12 my-2">
    <Link
      to={props.submenu.to}
      className="nav-link"
    >
      {props.submenu.menu}
    </Link>
  </div>
  );
};
export default CommitteeDetail;

// IsLoading effect is not working here
