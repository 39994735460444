import React from 'react'

const Read_more_details = (props) => {
    console.log(props.activeDetail);
  return (
    <div className='container bg11 text-light py-4 d-none' id='read_more_div'>
          <button
          onClick={closeFunction}
          className="btn btn-outline-light rounded-circle float-right"
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
        <h3 className='text-light'>{props.activeDetail.heading}</h3>
        <p className='text-xl text-light'>{props.activeDetail.description}</p>
    </div>
  )
}

function closeFunction() {
    document.getElementById("read_more_div").classList.add("d-none");
  }
export default Read_more_details