import Wrapper from "../layouts/wrapper";
import Vision from "./components/about_page/vision";
import Contact from './components/about_page/about_contact'
import Team from './components/about_page/our_team'
import Brochure from "./components/about_page/about_brochure";
import ApexMembers from "./components/about_page/apex_members";
import { useState } from "react";
import GlobalOutreach from "./components/about_page/global_outreach";
import Leadership from "./components/about_page/leadership";





const About = () => {

  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    // console.log(params);
  }

  const guestSubmenu = [
    {
      id: 1,
      menu: "Vision & Mission",
      to: "/about/vision",
    },
    {
      id: 6,
      menu: "Leadership",
      to: "/about/leadership",
    },
    {
      id: 2,
      menu: "Apex Members",
      to: "/about/apexMember",
    },
    // {
    //   menu: "Our Team",
    //   to: "/about/ourTeam",
    // },
    {
      id: 3,
      menu: "Brochure",
      to: window.api_storage_url + "/documents/Indian-Steell-Association-Brochure-2021.pdf",
    },
    {
      id: 4,
      menu: "Global Partnerships",
      to: "/about/globalpartnerships",
    },
    {
      id: 5,
      menu: "Contact us",
      to: "/about/contactUs",
    }
  ];
  const extranetSubmenu = [...guestSubmenu, { menu: '*documentation', to: '/about/docs' }, { menu: '*Former Secretary-Generals', to: 'about/general-secretary' }];



  return (
    <Wrapper key={guestSubmenu.id} getUserData={getUserData} menu="About" submenu={UserData.name ? extranetSubmenu : guestSubmenu}>
      {window.relativeUrl === "/about/vision" ? <Vision /> : ""}
      {window.relativeUrl === "/about/leadership" ? <Leadership /> : ""}
      {window.relativeUrl === "/about/apexMember" ? <ApexMembers /> : ""}
      {/* {window.relativeUrl === "/about/ourTeam" ? <Team /> : ""} */}
      {window.relativeUrl === "/about/brochure" ? <Brochure /> : ""}
      {window.relativeUrl === "/about/contactUs" ? <Contact /> : ""}
      {window.relativeUrl === "/about/globalpartnerships" ? <GlobalOutreach /> : ""}
    </Wrapper>
  );
};

export default About;
