import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../../layouts/wrapper";
import DateFunction from "../Datefunction";
import PageHeading from "../page_heding";

import SignupBox from "../signup_box";
import InnerWrapper from "../wrapper_3_9";

const RendezvousDetail = (props) => {
  const storyId = props.storyId;
  const [storyDetail, setstoryDetail] = useState([]);
  const [allStory, setAllStory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const custom_url = window.relativeUrl.split("/");
  console.log(custom_url[3]);
  useEffect(() => {
    setIsLoading(true);
    const url = new URL(window.api_url + "/storyDetail?slug=" + custom_url[3]);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstoryDetail(data.story);
        setAllStory(data.allStory);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [custom_url[3]]);

  return (
    <Wrapper>
      {/* <InnerWrapper
        col3={<Col3 allStory={allStory} />}
        col9={<Col9 storyDetail={storyDetail} isLoading={isLoading} />}
      /> */}

      <div className="container">
        <Col9 storyDetail={storyDetail} isLoading={isLoading} />
      </div>
      <div className="container">
        <LatestNews allStory={allStory} />
      </div>
    </Wrapper>
  );
};

const Col3 = (props) => {
  return (
    <React.Fragment>
      <SignupBox />
      {props.allStory.map((e) => (
        <Link to={"/media/rendezvous/" + e.url}>
          <div className="row p-1 mt-3 text-left pr-2">
            <div className="col-4">
              <img
                src={window.api_storage_url + "/images/uploads/" + e.image}
              />
            </div>
            <div className="col col-8">{e.heading}</div>
          </div>
        </Link>
      ))}
    </React.Fragment>
  );
};

const LatestNews = (props) => {

  return (
    <React.Fragment>
      <div className="row  ">
        <h1 className="px-3"> Related Posts</h1>
        <hr className="story_details_hr px-3" />
      </div>
      <div className="row p-1 mt-3 text-left pr-2 LatestNews_row">
        {props.allStory.slice(0, 4).map((e) => (
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 our_story_latest_box">
            <Link to={"/media/rendezvous/" + e.url}>
              <img
                src={window.api_storage_url + "/images/uploads/" + e.image}
              />
              <DateFunction date={e.date} />
              <h4>{e.heading.substr(0, 40)}...</h4>
              <div className="story_details_par_div">
                <p className="story_details_short_description" dangerouslySetInnerHTML={{ __html: e.description.substr(0, 130) + '...' }}></p>
                {/* <p className="story_details_short_description">{e.description.substr(0, 130)}...</p> */}

              </div>
            </Link>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

const Col9 = (props) => {
  return (
    <React.Fragment>
      {props.isLoading ? (
        <div />
      ) : (
        <div className="row affiliate_story">
          <div className="col col-12">
            <img
              src={
                window.api_storage_url +
                "/images/uploads/" +
                props.storyDetail.image2
              }
            />
            <div className="overlaped_h1">
              <span className="">{props.storyDetail.heading}</span>
            </div>

            <div className="our_story_details_description description_box"
              dangerouslySetInnerHTML={{
                __html: props.storyDetail.long_description,
              }}
            />
            <div className="our_story_details_description1">
              <div className="highlighted_story_box1">
                <span className="">
                </span>
              </div>
            </div>
            {/* <div className="our_story_details_description">
              <div className="highlighted_story_box">
                <span className=""> {props.storyDetail.heading}</span>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RendezvousDetail;
