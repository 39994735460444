import React, { useEffect, useState } from "react";
import FilterBlock from "../filter_block";
import PageHeading from "../page_heding";
import InnerWrapper from "../wrapper_3_9";
import ReadMore from "./read_more_details";
import GlossaryTechnologyBox from "./glossary_technology_box";

const Glossay_technology = () => {
  const [FilteredYear, setFilteredYear] = useState();
  const [FilteredMonth, setFilteredMonth] = useState("");
  const [Filter1, setFiltered1] = useState();
  const [Filter11, setFiltered11] = useState();

  function yearFilter(params) {
    setFilteredYear(params);
  }
  function monthFilter(params) {
    setFilteredMonth(params);
  }
  function filterFunction(params1, params2) {
    setFiltered1(params1);
    setFiltered11(params2);
  }
  const FilteredData = {
    filteredYear: FilteredYear,
    filteredMonth: FilteredMonth,
    filter1: Filter1,
    filter11: Filter11,
  };
  return (
    <>
      {/* <PageHeading h1="Glossary of Steel Technology " /> */}
      <InnerWrapper
        col3={
          <Col3
            yearFilter={yearFilter}
            filterFunction={filterFunction}
            filter={filter}
            FilteredData={FilteredData}
          />
        }
        col9={<Col9 FilteredData={FilteredData} />}
      />
    </>
  );
};

const filter = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const Col3 = (props) => {
  return (
    <>
      <FilterBlock
        filter1={filter}
        filterFunction={props.filterFunction}
        FilteredData={props.FilteredData}
      />
    </>
  );
};

const Col9 = () => {
  const contentData = [
    {
      id: 1,
      heading: "1.11 Bars",
      description:
        "mply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 2,
      heading: "1.12 Bars",
      description:
        "Lorem Ipsng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];

  const [state, setstate] = useState(contentData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/glossaryOfSteelTechnology");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [activeDetail, setactiveDetail] = useState(state[0]);
  function changeState(params) {
    setactiveDetail(params);

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.getElementById("read_more_div").classList.remove("d-none");
  }
  return (
    <>
      <div className="row my-4 ml-2">
        {state.map((e) => {
          return (
            <>
              <GlossaryTechnologyBox details={e} changeState={changeState} />
            </>
          );
        })}
        <ReadMore activeDetail={activeDetail} />
      </div>
    </>
  );
};
export default Glossay_technology;
