import { Link } from "react-router-dom";

const WeDoBox = (props) => {
    const data = props.we_do_data;
    return (
        <div className={data.bg_color + " we_do_box col-lg-3 col-md-6 col-sm-12"}>

            <h4 className=""> {data.heading} </h4>
            <p> {data.description} </p>
            <p></p>
            <p className="mt-4">
                {/* <i className={data.icon}></i> */}
                <img src={window.api_storage_url + "/uploads/" + data.image} alt="icons" />
            </p>
            {data.url.toLowerCase().startsWith("http", 0)
                ? <a href={data.url} target="_blank" rel="noreferrer" className="btn btn-primary m-3 d-none">Read More </a>
                : <Link to={data.url} className="btn btn-primary m-3 d-none">Read More </Link>
            }

        </div>
    );
}

export default WeDoBox;