import React, { useState } from "react";
import PageHeading from "../page_heding";

import CalenderYear from "../calender_year";
import SignupBox from "../signup_box";
import InnerWrapper from "../wrapper_3_9";
import MediaPage2 from "./media_page2";
import CalenderMonth from "../calender_month";
import MediaPage1 from "./media_page1";
import SubHeadingMenu from "../../components/sub_heading_menu";

const PressRelease = () => {

  const [FilteredYear, setFilteredYear] = useState();
  const [FilteredMonth, setFilteredMonth] = useState('');
  const [Filter1, setFiltered1] = useState();
  const [Filter11, setFiltered11] = useState();

  function yearFilter(params) {
    setFilteredYear(params);
  }
  function monthFilter(params) {
    setFilteredMonth(params);
  }
  function filterFunction(params1, params2) {
    setFiltered1(params1);
    setFiltered11(params2);
  }
  const FilteredData = {
    filteredYear: FilteredYear,
    filteredMonth: FilteredMonth,
  };
  return (
    <>
      {/* <SubHeadingMenu l1="Media" /> */}
      {/* <PageHeading l2="Press Relish" l1="media" /> */}

      <InnerWrapper
        col3={
          <Col3
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            filterFunction={filterFunction}
            FilteredData={FilteredData}
          />
        }
        col9={<Col9 FilteredData={FilteredData} />}
      />
    </>
  );
};

const year = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
];
const month = [
  { 'id': "01", 'name': 'Jan' },
  { 'id': "02", 'name': 'Feb' },
  { 'id': "03", 'name': 'Mar' },
  { 'id': "04", 'name': 'Apr' },
  { 'id': "05", 'name': 'May' },
  { 'id': "06", 'name': 'Jun' },
  { 'id': "07", 'name': 'Jul' },
  { 'id': "08", 'name': 'Aug' },
  { 'id': "09", 'name': 'Sep' },
  { 'id': "10", 'name': 'Oct' },
  { 'id': "11", 'name': 'Nov' },
  { 'id': "12", 'name': 'Dec' },
];
const Col3 = (props) => {
  return (
    <React.Fragment>
      <CalenderYear
        year={year}
        yearFilter={props.yearFilter}
        FilteredData={props.FilteredData}
      />
      <CalenderMonth
        month={month}
        monthFilter={props.monthFilter}
        FilteredData={props.FilteredData}
      />

      <SignupBox />
    </React.Fragment>
  );
};

const Col9 = (props) => {
  return (
    <React.Fragment>
      {window.relativeUrl === "/media/pressRelease" ? (
        <MediaPage1 FilteredData={props.FilteredData} media_type="1" />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default PressRelease;
