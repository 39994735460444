import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";


const InTheNews = (props) => {
  const initial_state = [
    {
      "id": 4,
      "created_at": "2022-06-18T08:20:56.000000Z",
      "updated_at": "2022-06-18T08:20:56.000000Z",
      "status": "1",
      "media_type_id": "2",
      "date": "2022-01-26",
      "heading": "Budget 2022: Steel Industry for continuation of anti-dumping duty",
      "description": "The steel industry is facing a twin blow that could further derail the developing Indian economy. First, it was the announcement of withdrawal of anti-dumping and countervailing duty on certain steel products in the 2021 budget, along with reduction in customs duty.",
      "url": "https://www.businesstoday.in/union-budget-2022/opinion/story/budget-2022-steel-industry-for-continuation-of-anti-dumping-duty-320407-2022-01-26",
      "source": "Business Today",
      "year": null,
      "filter": null,
      "image": "62ad8ae80a315.jpg",
      "press_release_attachment": null
    },
    {
      "id": 3,
      "created_at": "2022-05-25T15:43:50.000000Z",
      "updated_at": "2022-06-21T07:01:21.000000Z",
      "status": "1",
      "media_type_id": "2",
      "date": "2022-01-27",
      "heading": "India's top steelmakers seek federal funds to cut carbon emissions",
      "description": "India's top steelmakers have urged the government to provide federal funding and other economic assistance to help them meet targets for cutting carbon emissions, a leading industry body said.",
      "url": "https://www.reuters.com/world/india/indias-top-steelmakers-seek-federal-funds-cut-carbon-emissions-2022-01-27/",
      "source": "Reuters",
      "year": null,
      "filter": null,
      "image": "62b16cc13683d.jpg",
      "press_release_attachment": null
    },
    {
      "id": 2,
      "created_at": "2022-05-21T13:24:17.000000Z",
      "updated_at": "2022-06-21T07:04:48.000000Z",
      "status": "1",
      "media_type_id": "2",
      "date": "2021-12-28",
      "heading": "Increasing production, raw material security steel sector's focus in 2022",
      "description": "Increasing per capita steel consumption and production of special steel as well as enhancing raw material security will remain the key focus areas of the government in 2022.",
      "url": "https://www.business-standard.com/article/economy-policy/increasing-production-raw-material-security-steel-sector-s-focus-in-2022-121122800289_1.html",
      "source": "Busines Standard",
      "year": "2022",
      "filter": null,
      "image": "62b16d905b86d.jpg",
      "press_release_attachment": null
    }
  ];
  const [state, setstate] = useState(initial_state);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/media?media_type=2");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="in_the_news_div row p-5">
        <div className="container">
          <h2 className=""> ISA in the News</h2>

          <div className="row">
            <ReactOwlCarousel
              className="owl-theme"
              items="3"
              navText={['<', '>']}
              navClass={['arrow_left', 'arrow_right']}
              loop
              margin={10}
              autoplay
              responsive={{
                0: {
                  items: 1
                },
                600: {
                  items: 2,
                  margin: 2
                },
                1000: {
                  items: 3,
                  margin: 10
                }
              }
              }
            >
              {isLoading ? (
                <Cards block_detail={state} />
              ) : (
                <Cards block_detail={state} />
              )}
            </ReactOwlCarousel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Cards = (props) => {
  return (
    <React.Fragment>
      {props.block_detail.map((e) => (
        <Card key={e.id} block_detail={e} />
      ))}
    </React.Fragment>
  );
};

const Card = (props) => {
  return (
    <div className="item">
      <div className="card in_the_news_card">
        <img
          src={
            window.api_storage_url + "/images/uploads/" + props.block_detail.image}
          alt="Card cap"
        />
        <div className="card-body">
          <DateFunction date={props.block_detail.date} />
          <h4 className="card-title text-left pt-2">{props.block_detail.heading}</h4>
          <div className="text-right">
            <a href={props.block_detail.url} target="_blank" rel="noreferrer" className="btn btn-primary in_the_news_btn">
              Read More &nbsp;<i className="fa-solid fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// const LoadingCard = (props) => {
//   return (
//     <div className="item">
//       <div className="card in_the_news_card main_loading_box">

//       </div>
//     </div>
//   );
// };

function DateFunction(props) {
  const d = new Date(props.date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <button className="float-right fw-bold in_the_news_date">
        {" "}

        {months[d.getMonth()]} {d.getDate()},  {d.getFullYear()}{" "}
      </button>
    </>
  );
}

export default InTheNews;
