import { Link } from "react-router-dom";

const FilterBlock = (props) => {
  
    return (
    <div className="calender_year text-left">
      <h4 className="d-inline"> Filter </h4> <span className="clear_filter" onClick={()=>props.filterFunction('','')}> Clear Filter</span>
      <div className="row text-center pr-2">
        {props.filter1.map((e) => (
          <span key={e} onClick={()=>props.filterFunction(e,'jdh')}className="calender_year__col p-1">
            {" "}
            <div className={props.FilteredData.filter1==e ? "calender_year__block p-2 filter_active" : "calender_year__block p-2"}> {e} </div>{" "}
          </span>
        ))}
      </div>
    </div>
  );
};

function clickFilter(params) {
    alert(params)
}
export default FilterBlock;
