import { useEffect, useState } from "react";
import Wrapper from "../layouts/wrapper";

const DetailedPage = () => {
  
  const page_id = window.relativeUrl.split('/')[2];

  const [state, setstate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
    const url = new URL(window.api_url+"/pageDetail?page_id="+page_id);

    
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    };
    
    fetch(url, {
        method: "GET",
        headers,
    })
    .then(response => response.json()).then(data => {
      setstate(data.page_detail);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}, []);
    
  return (
    <Wrapper>
      {state.long_description}
    </Wrapper>
  );
};

export default DetailedPage;
