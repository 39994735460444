import React, { useState } from "react";

import CalenderYear from "../../components/calender_year";
import SignupBox from "../../components/signup_box";
import InnerWrapper from "../../components/wrapper_3_9";
import { Link } from "react-router-dom";
import ReportList from "./report_list";

const Report = () => {
  const [FilteredYear, setFilteredYear] = useState();
  const [Filter1, setFiltered1] = useState();
  const [Filter11, setFiltered11] = useState();

  function yearFilter(params) {
    setFilteredYear(params);
  }
  function filterFunction(params1, params2) {
    setFiltered1(params1);
    setFiltered11(params2);
  }
  const FilteredData = {
    filteredYear: FilteredYear,
    filter1: Filter1,
    filter11: Filter11,
  };
  return (
    <InnerWrapper
        col3={
          <Col3
            yearFilter={yearFilter}
            filterFunction={filterFunction}
            filter={filter}
            FilteredData={FilteredData}
          />
        }
        col9={<Col9 FilteredData={FilteredData} />}
      />
  );
};

const year = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
];
const filter = ["Report", "Article", "Archive"];
const Col3 = (props) => {
  return (
    <React.Fragment>
      <FilterBlock
        filter={filter}
        filterFunction={props.filterFunction}
        FilteredData={props.FilteredData}
      />
      <CalenderYear
        year={year}
        yearFilter={props.yearFilter}
        FilteredData={props.FilteredData}
      />
      <SignupBox />
    </React.Fragment>
  );
};

const Col9 = (props) => {
  return (
    <React.Fragment>
      {window.relativeUrl === "/knowledgeBank/externalReport/Report" ? (
        <ReportList FilteredYear={props.FilteredYear} report_type="1" />
      ) : (
        ""
      )}
      {window.relativeUrl === "/knowledgeBank/externalReport/Article" ? (
        <ReportList FilteredYear={props.FilteredYear} report_type="2" />
      ) : (
        ""
      )}
      {window.relativeUrl === "/knowledgeBank/externalReport/Archive" ? (
        <ReportList FilteredYear={props.FilteredYear} report_type="3" />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const FilterBlock = (props) => {
  return (
    <div className="calender_year text-left">
      <h4> Filter </h4>
      <div className="row text-center pr-2">
        {props.filter.map((e) => (
          <Link
            to={"/knowledgeBank/externalReport/" + e}
            key={e}
            className="calender_year__col p-1"
          >
            {" "}
            <div className="calender_year__block p-2"> {e} </div>{" "}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Report;
