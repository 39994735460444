const CalenderYear = (props) => {

    return (
        <div className="calender_year text-left mt-4">
            <h4 className="d-inline"> Year  </h4> <span className="clear_filter" onClick={()=>props.yearFilter()}> Clear Filter</span>
            <div className="row text-center pr-2">
                {props.year.map(e => <Years key={e} year={e} yearFilter={props.yearFilter} FilteredData={props.FilteredData}/> )}
                
            </div>
        </div>
    )
}

function Years(props) {
    return(
        <div className="calender_year__col p-1 col col-3"> <div  className={props.FilteredData.filteredYear==props.year ? "calender_year__block p-2 filter_active" : "calender_year__block p-2"}  onClick={()=>props.yearFilter(props.year)}> {props.year} </div> </div>
    )
}
export default CalenderYear;