import React from "react";
import { Link } from "react-router-dom";
import NewsDate from "./date_time/news_date";

// import ReactHtmlParser from 'react-html-parser'


const Block1 = (props) => {
  return (
    <React.Fragment>
      <div key={props.block_detail.id} className="row event_block_row m-3 mb-4">

        <div className="col col-12 text-left">
          <h3 className=" pl-3 pt-3">{props.block_detail.heading} </h3>
          <NewsDate data={props.block_detail} />
          <p className="pl-3">
            <div dangerouslySetInnerHTML={{ __html: props.block_detail.description }} />
          </p>
          {props.block_detail.url
            ?
            props.block_detail.url.toLowerCase().startsWith("http", 0)
              ? <a href={props.block_detail.url} target="_blank" rel="noreferrer" className="btn btn-primary m-3">Read More </a>
              : <Link to={props.block_detail.url} className="btn btn-primary m-3">Read More</Link>

            : ""
          }

          {props.block_detail.govt_notification_attachment
            ? <a href={window.api_storage_url + "/documents/govt_notifications/" + props.block_detail.govt_notification_attachment} target="_blank" rel="noreferrer" className="btn btn-primary m-3"> Download <i className="fa-solid fa-download"></i> </a> : ""}
          {props.block_detail.press_release_attachment
            ? <a href={window.api_storage_url + "/documents/press_release/" + props.block_detail.press_release_attachment} target="_blank" rel="noreferrer" className="btn btn-primary m-3"> Download <i className="fa-solid fa-download"></i> </a> : ""}
          {props.block_detail.insights_attachment
            ? <a href={window.api_storage_url + "/documents/insights/" + props.block_detail.insights_attachment} target="_blank" rel="noreferrer" className="btn btn-primary m-3"> Download <i className="fa-solid fa-download"></i> </a> : ""}
          {props.block_detail.report_attachment
            ? <a href={window.api_storage_url + "/documents/reports/" + props.block_detail.report_attachment} target="_blank" rel="noreferrer" className="btn btn-primary m-3"> Download <i className="fa-solid fa-download"></i> </a> : ""}
        </div>
      </div>

    </React.Fragment>
  )
}


export default Block1;