import Wrapper from "../layouts/wrapper";

const submenu = [
  {
    menu: "Glossary of Steel technology",
    to: "/knowledgeBank/glossary-of-steel-technology",
  },
  {
    menu: "*External Report",
    to: "/knowledgeBank/externalReport",
  },
  {
    menu: "*ISA Annual Report",
    to: "/knowledgeBank/annualReport",
  },
  {
    menu: "Steel Technology",
    to: "/knowledgeBank/steelTechnology",
  },
  {
    menu: "CSR",
    to: "/knowledgeBank/csr",
  },
  {
    menu: "ISA Insights",
    to: "/knowledgeBank/insights",
  },
];
const Insight = () => {
  return <Wrapper menu="Knowledge Bank" submenu={submenu}>
    <div className="container">
    <div className="row">
        <div className="text-left col col-12">
            sdkjkls
        </div>
    </div>
    </div>
  </Wrapper>;
};

export default Insight;
