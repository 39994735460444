import { useState } from "react";
import Wrapper from "../layouts/wrapper";
import AffiliateMembers from "./components/members/affiliate_member";
import ExecutiveMembers from "./components/members/executive_member";
import AssociateMembers from "./components/members/associate_member";

const guestSubmenu = [
  {
    id: 1,
    menu: "Affiliate Members",
    to: "/members/MemberAffiliate",
  },
  {
    id: 2,
    menu: "Associate Members",
    to: "/members/MemberAssociate",
  },
  {
    id: 3,
    menu: "Executive Members",
    to: "/members/MemberExecutive",
  }
];

const extranetSubmenu = [...guestSubmenu, { menu: '*Antitrust Guidelines', to: '/Antitrust/docs' }, { menu: '*Annual General Meeting', to: 'Annual-genral-meeting/meetings' }];

// console.log(extranetSubmenu);

const Members = () => {
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    // console.log(params);
  }

  const custom_url = window.relativeUrl.split("/");
  return (
    <Wrapper getUserData={getUserData} menu="Members" submenu={UserData.name ? extranetSubmenu : guestSubmenu}>
      {custom_url[2] === "executive" ? <ExecutiveMembers /> : ""}
      {custom_url[2] === "affiliate" ? <AffiliateMembers /> : ""}
      {custom_url[2] === "associate" ? <AssociateMembers /> : ""}
    </Wrapper>
  );
};

export default Members;
