import React from "react";
import './block_podcast.css';

const Block1 = (props) => {
  let url = "https://anchor.fm/indiansteelassociation/embed/episodes/COP26-How-steel-could-become-green-e1cklbp/a-a76a6fm";
  return (
    <React.Fragment>
      <div key={props.block_detail.id} className="row event_block_row m-2 p-3">
        <div className="col col-4 text-left">
          {/* <iframe src={props.block_detail.url} height="110px" width="100px" frameborder="0" scrolling="no"></iframe> */}



          <iframe src={props.block_detail.url} height="110px" width="100px" frameBorder="0" scrolling="no"></iframe>
        </div>
        <div className="col col-8 text-left">
          <h3 className="mt-2"> {props.block_detail.heading} | {props.block_detail.date}  </h3>
          <div dangerouslySetInnerHTML={{ __html: props.block_detail.description }} />
        </div>
      </div>

    </React.Fragment >
  )
}


export default Block1;