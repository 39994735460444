import React, { useState } from "react";
// import Wrapper from "../layouts/wrapper";
import Wrapper from "../layouts/nofitications";
import CalenderYear from "./components/calender_year";
import PageHeading from "./components/page_heding";
import SignupBox from "./components/signup_box";
import InnerWrapper from "./components/govtmenu_left";
import FilterBlock from "./components/filter_block";
import NotificationList from "./components/govt_notifications/notification_list";
import CalenderMonth from "./components/calender_month";

const submenu = [
  {
    id: 1,
    menu: "Trade",
    to: "/govtNotifications/trade",
  },
  {
    id: 2,
    menu: "Environment",
    to: "/govtNotifications/environment",
  },
  {
    id: 3,
    menu: "Sustainability",
    to: "/govtNotifications/sustainability",
  },
  {
    id: 4,
    menu: "Raw Material",
    to: "/govtNotifications/rawMaterial",
  },
  {
    id: 5,
    menu: "Power",
    to: "/govtNotifications/power",
  },
];
const GovtNotification = () => {
  const [FilteredYear, setFilteredYear] = useState();
  const [FilteredMonth, setFilteredMonth] = useState("");
  const [Filter1, setFiltered1] = useState();
  const [Filter11, setFiltered11] = useState();

  function yearFilter(params) {
    setFilteredYear(params);
  }
  function monthFilter(params) {
    setFilteredMonth(params);
  }
  function filterFunction(params1, params2) {
    setFiltered1(params1);
    setFiltered11(params2);
  }
  const FilteredData = {
    filteredYear: FilteredYear,
    filteredMonth: FilteredMonth,
    filter1: Filter1,
    filter11: Filter11,
  };
  return (
    <Wrapper menu="Govt. Notifications " submenu={submenu} key={submenu.id}>
      {/* <PageHeading h1="Government Notification" /> */}
      <InnerWrapper
        col3={
          <Col3
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            filterFunction={filterFunction}
            FilteredData={FilteredData}
          />
        }
        col9={<Col9 FilteredData={FilteredData} />}
      />
    </Wrapper>
  );
};

const year = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
];
const month = [
  { id: "01", name: "Jan" },
  { id: "02", name: "Feb" },
  { id: "03", name: "Mar" },
  { id: "04", name: "Apr" },
  { id: "05", name: "May" },
  { id: "06", name: "Jun" },
  { id: "07", name: "Jul" },
  { id: "08", name: "Aug" },
  { id: "09", name: "Sep" },
  { id: "10", name: "Oct" },
  { id: "11", name: "Nov" },
  { id: "12", name: "Dec" },
];
// const filter = [
//   "Steel Industry",
//   "Steel Association",
//   "Import Duties",
//   "Raw Material",
//   "Industrial Sector",
//   "ISA",
//   "Budget 2022",
// ];
const Col3 = (props) => {
  return (
    <React.Fragment>
      {/* <FilterBlock filter1={filter} FilteredData={props.FilteredData} /> */}
      <CalenderYear
        year={year}
        yearFilter={props.yearFilter}
        FilteredData={props.FilteredData}
      />
      <CalenderMonth
        month={month}
        monthFilter={props.monthFilter}
        FilteredData={props.FilteredData}
      />
      <SignupBox />
    </React.Fragment>
  );
};

const Col9 = (props) => {
  return (
    <React.Fragment>
      {window.relativeUrl === "/govtNotifications/ForeignTradePolicy" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="6"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/ForeignTradeAgreements" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="7"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/SIMS" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="8"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/QualityControlOrders" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="9"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/TechnicalCommitteeReports" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="10"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/Air" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="11"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/Water" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="12"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/NoiseSound" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="13"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/IndustrialNorms" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="14"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/SolidWasteDisposal" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="15"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/EIASustainability" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="16"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/HydrogenPolicy" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="17"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/CoalActs" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="18"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/CoalRules" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="19"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/CoalAmendments" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="20"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/IronOreActs" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="21"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/IronOreRules" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="22"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/IronOreAmendments" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="23"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/OthersActs" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="24"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/OthersRules" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="25"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/OthersAmendments" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="26"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/ElectricityRules" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="27"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/PolicyonGfreening" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="28"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/ElectricityDuty" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="29"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RenewableEnergy" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="30"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/PromotionRD" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="31"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/NonTariffMeasures" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="32"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/EnvironmentImpactAssessment" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="33"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/Sustainability" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="34"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/OtherNotifications" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="35"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/OtherGuidelines" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="36"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RailwayActs" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="37"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RailwayRules" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="38"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RailwayGuidelines" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="39"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RailwayAmendments" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="40"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RailwayOther" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="41"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/ActsMinesMineral" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="42"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/RulesMinesMineral" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="43"
        />
      ) : (
        ""
      )}
      {window.relativeUrl === "/govtNotifications/AmendmentsMinesMineral" ? (
        <NotificationList
          FilteredData={props.FilteredData}
          notification_type="44"
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default GovtNotification;
