import { Link } from "react-router-dom";

const Menulist = (props) => {
  return (
    <>

      <li className={window.relativeUrl === props.submenu.to ? "active-left_menu left_menu" : "left_menu"} style={{ listStyleType: 'none' }}>
        {props.submenu.committee_submenu ? (
          <>
            <details>
              <summary onClick={displayBlock}> {props.submenu.menu} </summary>

              <ul id="submenu_ul" className="ml-4 d-none" >
                {props.submenu.committee_submenu.map((e) => (
                  <li
                    className={
                      window.relativeUrl === e.to
                        ? "active-left_menu left_menu"
                        : "left_menu"
                    } key={e.key} > <Link id="left_menu_link" to={e.to}> {e.menu}</Link> </li>
                ))}
              </ul>
            </details>
          </>
        ) : (
          <Link id="left_menu_link" to={props.submenu.to}>
            <span onClick={displayNone}> {props.submenu.menu} </span>
          </Link>
        )}
      </li>
    </>
  );
};
function displayBlock(params) {
  // document.getElementById('submenu_ul').classList.remove('d-none')
  document.getElementById('submenu_ul').classList.toggle('d-none')
}

function displayNone(params) {
  document.getElementById('submenu_ul').classList.add('d-none')
}
export default Menulist;
