import React, { useState, useEffect } from "react";
import "../about_page/about.css";
import VisionBanner from "./vision_banner";
function GlobalOutreach() {

  const [state, setstate] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/golbalpartnership");
    console.log("hello " + url);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        console.log(data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(state);
  return (
    <section>
      <VisionBanner data={state} key={state.id} />
      <div className="container py-4 vision_container">
        <h1 className="text-center text-3xl color11">{state.heading1}</h1>
        <p className="py-4 text-xl text-left vision_para" dangerouslySetInnerHTML={{ __html: state.text1 }} />
        <div className="row px-8 d-flex justify-content-between">
          <div className="col-12 description_box" id="mission">
          </div>
        </div>
      </div>
    </section>
  );
}

export default GlobalOutreach;
