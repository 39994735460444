import Wrapper from "../layouts/wrapper";
import IsaInsight from '../pages/components/knowledge_bank/isa_insight'
import IsaArticle from '../pages/components/knowledge_bank/isa_article'
import Blog from '../pages/components/knowledge_bank/isa_blog'
import Csr from './components/knowledge_bank/csr_'
import SteelTechnology from "./components/knowledge_bank/steel_technology";
import GlossayTechnology from "./components/knowledge_bank/glossay_technology";
import Report from "./components/knowledge_bank/report";
import AnualReport from "./components/knowledge_bank/annual_report";
import { useState } from "react";
import IsaPodcast from '../pages/components/knowledge_bank/isa_podcast_copy'
import SteelTrends from "./components/steel_trends/SteelTrends";
// import IsaPodcast from '../pages/components/knowledge_bank/isa_podcast'

const guestSubmenu = [
  {
    id: 1,
    menu: "Steel Data",
    to: "/knowledgeBank/steel-trends",
  },
  {
    id: 2,
    menu: "Glossary of Steel technology",
    to: "/knowledgeBank/glossary-of-steel-technology",
  },
  // {
  //   id: 2,
  //   menu: "Blog",
  //   to: "/knowledgeBank/blog",
  // },
  // {
  //   id: 3,
  //   menu: "Article",
  //   to: "/knowledgeBank/article",
  // },
  {
    id: 3,
    menu: "Podcast",
    to: "/knowledgeBank/podcast",
  },
  {
    id: 4,
    menu: "ISA Insights",
    to: "/knowledgeBank/insights",
  },
];

const extranetSubmenu = [...guestSubmenu, {
  menu: "*Monthly Review",
  to: "/knowledgeBank/externalReport/Report",
},
{
  menu: "*Annual Report",
  to: "/knowledgeBank/annualReport",
}
]

const KnowledgeBank = () => {
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    // console.log(params);
  }

  const custom_url = window.relativeUrl.split("/");
  return (
    <Wrapper getUserData={getUserData} menu="Knowledge Bank" submenu={UserData.name ? extranetSubmenu : guestSubmenu}>
      {window.relativeUrl === "/knowledgeBank/insights" ? <IsaInsight /> : ""}
      {/* {window.relativeUrl === "/knowledgeBank/blog" ? <Blog /> : ""} */}
      {window.relativeUrl === "/knowledgeBank/podcast" ? <IsaPodcast /> : ""}
      {/* {window.relativeUrl === "/knowledgeBank/article" ? <IsaArticle /> : ""} */}
      {custom_url[2] === "csr" ? <Csr /> : ""}
      {window.relativeUrl === "/knowledgeBank/steelTechnology" ? <SteelTechnology /> : ""}
      {custom_url[2] === "externalReport" ? <Report /> : ""}
      {custom_url[2] === "annualReport" ? <AnualReport /> : ""}
      {window.relativeUrl === "/knowledgeBank/glossary-of-steel-technology" ? <GlossayTechnology /> : ""}
      {window.relativeUrl === "/knowledgeBank/steel-trends" ? <SteelTrends /> : ""}

    </Wrapper>
  );
};

export default KnowledgeBank;
