import React from 'react'

const Loading = () => {
  return (
    <div className='loading_box'>
      {/* <div class="lds-facebook"><div></div><div></div><div></div></div> */}
    </div>
  )
}

export default Loading