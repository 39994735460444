import React, { useEffect, useState } from "react";
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const initial_state = [
  {
    "id": 33,
    "slider_no": "4",
    "status": "1",
    "image": "18052373221AMbanner-444.jpg",
    "heading": "ISA Apex Committee Meet",
    "subheading1": "ISA Apex Committee",
    "subheading2": "meet at Hotel Taj Mahal",
    "subheading3": "New Delhi",
    "description": "25th Apex Committee Meeting",
    "date": "2023-05-18",
    "type": "Meeting",
    "created_at": "2023-05-04T09:35:32.000000Z",
    "updated_at": "2023-05-18T07:32:23.000000Z"
  },
  {
    "id": 3,
    "slider_no": "1",
    "status": "1",
    "image": "18052372720AMbanner-011.jpg",
    "heading": "ISA Steel Conclave 2022",
    "subheading1": "Shri Jyotiraditya Scindia",
    "subheading2": "Inaugurates 3rd Edition",
    "subheading3": "of ISA Steel Conclave",
    "description": "ISA Steel Conclave 2022",
    "date": "2023-05-18",
    "type": "Event",
    "created_at": "2022-11-29T09:37:24.000000Z",
    "updated_at": "2023-05-18T07:27:20.000000Z"
  },
  {
    "id": 2,
    "slider_no": "2",
    "status": "1",
    "image": "18052372932AMbanner-222.jpg",
    "heading": "ISA Steel Conclave 2022",
    "subheading1": "Shri Piyush Goyal",
    "subheading2": "Addresses the",
    "subheading3": "Steel Industry",
    "description": "Indian Steel Conclave 2022",
    "date": "2023-05-18",
    "type": "Event",
    "created_at": "2022-06-01T13:22:09.000000Z",
    "updated_at": "2023-05-18T07:29:32.000000Z"
  },
  {
    "id": 1,
    "slider_no": "3",
    "status": "1",
    "image": "18052373045AMbanner-333.jpg",
    "heading": "ISA Steel Conclave 2022",
    "subheading1": "Export Duty On",
    "subheading2": "Iron and Steel",
    "subheading3": "Products Removed",
    "description": "Industry Hails Decision",
    "date": "2023-05-18",
    "type": "Event",
    "created_at": "2022-06-16T07:18:10.000000Z",
    "updated_at": "2023-05-18T07:30:45.000000Z"
  }
]

function Banner() {
  const [getapi, setGetapi] = useState(initial_state);
  const getApiData = async () => {
    const res = await axios.get(window.api_url + "/slider1");
    setGetapi(res.data);
  }

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <React.Fragment>
      <div className="mx-auto home-carosal-wrap">
        <Carousel
          thumbWidth={350}
          renderThumbs={() =>
            getapi.map((e, i) =>
              <SliderItem i={i} key={e.id} data={e} />
            )
          }
          showStatus={false}
          useKeyboardArrows={true}
          emulateTouch={true}
          autoPlay={true}
          infiniteLoop
          interval={3000}
          showArrows={false}
          swipeable={true}
          stopOnHover={true}
          animationHandler={"fade"}
        >
          {getapi.map((e) => (
            <BannerBox key={e.id} data={e} />
          ))}
        </Carousel>
      </div>
    </React.Fragment>
  );
}

const BannerBox = (props) => {
  return (
    <section>
      <div className="container-fluid">
        <div className="containe home_banner_container px-0">
          <div className="row">
            <div className="col col-12 px-0 banner_image_div">
              <div className="banne_div_img">
                <img
                  className="h-100"
                  src={
                    window.api_storage_url +
                    "/images/sliders/" +
                    props.data.image
                  }
                  alt="home_banner"
                />
              </div>

              {/* <button className="btn btn-outline-light float-left mx-8 mt-4">Hybrid Event</button> */}
              <div id="left_container" className="left_container">
                <div className="top_banner_heading_container text-left">
                  <div className="div1">
                    <h1 className="px-2" id="home_banner_heading">
                      {props.data.subheading1}
                    </h1>
                  </div>
                  {props.data.subheading2 ? (
                    <div className="div2">
                      <h1 className="px-2" id="home_banner_heading">
                        {props.data.subheading2}
                      </h1>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.data.subheading3 ? (
                    <div className="div3">
                      <h1 className="px-2" id="home_banner_heading">
                        {props.data.subheading3}
                      </h1>
                    </div>
                  ) : (
                    ""
                  )}

                  <p className="text-white text-xl py-4 " id="home_banner_para">
                    {props.data.description}
                  </p>
                  <DateFunction date={props.data.date} />
                  <button
                    className="float-left btn btn-light mt-2"
                    id="home_banner_btn"
                  >
                    Read More <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SliderItem = (props) => {
  return (
    <div className='row slider_item_container'>
      <div className='col-3 d-flex align-items-center justify-content-center'>
        <h1 className='section_id'>
          {props?.i + 1}
        </h1>
      </div>
      <div className='col-9 d-flex align-items-center w-100' style={{position:"relative",flexWrap:"wrap"}}>
        <div>
          <div className='text-right' style={{position:"absolute",right:'15px',top:0}}>
            <button  className={'bg22 px-3 py-1 text-light section2_slider_btn' + props.data.type}>{props.data.type}</button>
          </div>
          <div style={{whiteSpace:"pre-line"}}>
            <h1 className='py-2 section2_heading'>{props.data.heading}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

function DateFunction(params) {
  const d = new Date(params.date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <p className="text-left fw-bold dateColor banner_date">
        {" "}
        {d.getDate()} {months[d.getMonth()]}, {d.getFullYear()}{" "}
      </p>
    </>
  );
}

export default Banner;
