

const GlossaryTechnology = (props) => {
    return (
        <div onClick={() => props.changeState(props.details)} className='col-lg-4 col-md-6 col-sm-12 my-2 px-4 pb-4 glossary_coloumn'>
        <h1 className=''>{props.details.heading}</h1>
         <p className='my-2 '>{props.details.description}</p>
        </div>
    )
}

export default GlossaryTechnology;