import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
export default function SteelTrends() {
    const [categories, setCategories] = useState([])
    const [data, setData] = useState([])
    const [actLinkInfo, setActLinkInfo] = useState({ id: -1, data: {}, catId: -1 })

    useEffect(() => {
        // fetchTrends();
        fetchCategories();

        const handleContextMenu = (event) => event.preventDefault();
        window.addEventListener('contextmenu', handleContextMenu);
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [])

    const fetchTrends = () => {
        const url = new URL(window.api_url + "/steel-trends");
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        fetch(url, {
            method: "GET",
            headers,
        }).then((response) => response.json())
            .then((data) => {
                if (data?.length > 0) {
                    let url = new URLSearchParams(window.location.search)
                    if (url?.get("id")) {
                        let filter = data.filter((item) => url?.get("id") == item?.id)
                        if (filter.length > 0) {
                            setActLinkInfo({ ...actLinkInfo, id: filter[0]?.id, data: filter[0] })
                        }
                    } else {
                        setActLinkInfo({ ...actLinkInfo, id: data[0]?.id, data: data[0] })
                    }
                    setData(data)
                }
            })
            .catch((error) => {
            });
    }

    const fetchCategories = () => {
        const url = new URL(window.api_url + "/steel-trends-categories");
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        fetch(url, {
            method: "GET",
            headers,
        }).then((response) => response.json()).then((data) => {
            if (data?.length > 0) {
                setCategories(data)
                let firstId = null, firstCatId = null;
                data?.some(item => {
                    if (item?.steel_trend?.length > 0) {
                        firstId = item?.steel_trend[0]?.id;
                        firstCatId = item?.steel_trend[0]?.category_id;
                        return true
                    }
                })
                firstId && fetchTrendByCategory(firstId, firstCatId)
            }
        }).catch((error) => {
        });
    }

    const fetchTrendByCategory = (id, catId) => {
        const url = new URL(window.api_url + `/steel-trend/detail/${id}`);
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        fetch(url, {
            method: "GET",
            headers,
        }).then((response) => response.json()).then((data) => {
            if (Object.keys(data)?.length > 0) {
                setActLinkInfo({ ...actLinkInfo, id, data, catId })
            }
        }).catch((error) => {
        });
    }

    const handlePDF = (url) => window.open(url, '_blank', 'noopener,noreferrer');

    return (
        <React.Fragment>
            <div className="row mt-4 steel_trends" onCopy={(e) => e.preventDefault()}>
                <div className="col-md-3 px-4">
                    <h4>Statistics</h4>
                    <ul id="accordion">
                        {categories?.map((item, index) => <li key={item?.name}>
                            <p
                                className={`menu btn ${item?.id == actLinkInfo?.catId ? "active" : ""}`}
                                data-toggle={'collapse'}
                                data-target={`#${item?.name}_collapse_${index}`}
                                aria-expanded="true"
                                aria-controls={`${item?.name}_collapse_${index}`}>
                                {item?.name}
                            </p>
                            <ul
                                className='collapse multi-collapse'
                                id={`${item?.name}_collapse_${index}`} >
                                {item?.steel_trend?.map((subcat) => <li
                                    key={item?.name + "_" + subcat?.title}
                                    className={`show sub_menu ${subcat?.id == actLinkInfo?.id ? "active" : ""}`}
                                    onClick={() => fetchTrendByCategory(subcat?.id, subcat?.category_id)} >
                                    <i className="fa-solid fa-dot-circle mr-1"></i>
                                    {subcat?.title}
                                </li>)}
                            </ul>
                        </li>)}
                    </ul>
                </div>
                <div className="col-md-9">
                    {actLinkInfo?.data?.attached_file &&
                        <div className='trend-table-head'>
                            <button className='btn btn-secondary' onClick={() => handlePDF(actLinkInfo?.data?.attached_file)}>
                                View PDF
                            </button>
                        </div>}
                    {actLinkInfo?.id !== -1 &&
                        <div className='steel_trend_table' dangerouslySetInnerHTML={{ __html: actLinkInfo?.data?.description }}></div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
