import Wrapper from "../layouts/wrapper";
import Insight from "./components/home_page/insights";
import InTheNews from "./components/home_page/in_the_news";
import Events from "./components/home_page/events_meetings";
import WeDo from "./components/home_page/what_we_do";
import Slider2 from "./components/home_page/section2_slider";
import SliderNew from "./components/home_page/section2_new";
import Banner from "./components/home_page/Banner";
import Banner2 from "./components/home_page/Banner2";
import { Helmet } from "react-helmet";
import { useState } from "react";



const Home = () => {
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    console.log(params);
  }
  return (
    <Wrapper getUserData={getUserData}>
      <Helmet>
        <title>Indian Steel Association </title>
        <meta name="description" content="Indian Steel Association" />

      </Helmet>
      {/* <Banner2 /> */}
      <Banner />
      {/* <Slider2 /> */}
      {/* <Slider2 /> */}
      {/* <SliderNew /> */}
      {UserData.name ? <Events /> : ""}
      <InTheNews />
      <Insight />
      <WeDo />
    </Wrapper>
  );
};

export default Home;
