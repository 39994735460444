
import React from "react";



const LoadingBlock = () => {
    return ( 
        <React.Fragment>
            <div className="row event_block_row m-3 mb-4">
         
         <div className="loading_box">
           
         </div>
       </div>
        </React.Fragment>
     );
}
 
export default LoadingBlock;