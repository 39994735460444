import { Routes, Route } from "react-router-dom";
import "../App.css";
import "../custom.css";
import "../Homemedia.css"
import "../pages/components/about_page/about_responsive.css"
import Home from "../pages/home";
import Events from "../pages/events";
import Media from "../pages/media";
import Committees from "../pages/committees";
import OurMembers from "../pages/members";
import MemberExecutive from "../pages/MemberExecutive";
import MemberAffiliate from "../pages/MemberAffiliate";
import MemberAssociate from "../pages/MemberAssociate";
import OurMembersHome from "../pages/members_home";
import KnowledgeBank from "../pages/knowledge_bank";
import Notification from "../pages/govt_notifications";
import Error404 from "../pages/errors/404";
import About from "../pages/about";
// import Insight from "../pages/components/home_page/insights";
import Insight from "../pages/Insight";
import DetailedPage from "../pages/detailed_page";
import Login from "../pages/Auth/Login";
import Extranet from "../pages/Auth/extranet";
import OurStoryDetail from "../pages/components/media/our_story_detail";
import RendezvousDetail from "../pages/components/media/rendezvous_detail";
import SteelTrends from "../pages/components/steel_trends/SteelTrends";
import Policy from "../pages/policy";


const RouteComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/build" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="about/:about_url" element={<About />} />
      <Route path="members" element={<OurMembersHome />} />
      <Route path="members/MemberExecutive" element={<MemberExecutive />} />
      <Route path="members/MemberAffiliate" element={<MemberAffiliate />} />
      <Route path="members/MemberAssociate" element={<MemberAssociate />} />
      <Route path="members/executive" element={<OurMembers />} />
      <Route path="members/affiliate" element={<OurMembers />} />
      <Route path="members/executive/:member_url" element={<OurMembers />} />
      <Route path="members/affiliate/:member_url" element={<OurMembers />} />
      <Route path="members/associate/:member_url" element={<OurMembers />} />
      <Route path="committees/:committee_url" element={<Committees />} />
      <Route path="events/committeeEvents" element={<Events />} />
      <Route path="events/isaEvents" element={<Events />} />
      <Route path="events/externalNews" element={<Events />} />
      <Route path="media/:mediaType" element={<Media />} />
      <Route path="media/ourStory/:storyID" element={<OurStoryDetail />} />
      <Route path="media/ourStory/:rendeID" element={<RendezvousDetail />} />
      <Route path="knowledgeBank/:knowledge_bank" element={<KnowledgeBank />} />
      <Route path="knowledgeBank/:knowledge_bank/:type" element={<KnowledgeBank />} />
      <Route path="knowledgeBank/steel-trends" element={<KnowledgeBank />} />
      <Route path="knowledgeBank/steel-trends/:id" element={<KnowledgeBank />} />


      <Route path="govtNotifications/:notification" element={<Notification />} />
      <Route path="knowledgeBank/insights/:insights" element={<Insight />} />
      <Route path="Extranet/" element={<Extranet />} />
      <Route path="ExtranetLogin/" element={<Login />} />
      <Route path="d/*" element={<DetailedPage />} />
      {/* <Route path="admin/login/" element={<AdminLogin />} loc="http://127.0.0.1:8000/login" /> */}
      {/* <Route path='/admin/login/' component={() => { window.location = 'http://127.0.0.1:8000/login'; return null; }} /> */}

      <Route path="privacy-policy" element={<Policy />} />
      <Route path="*" element={<Home />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default RouteComponent;
