import React, { useEffect, useState } from 'react'
import Wrapper from '../../layouts/wrapper';
import './login.css';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom"


const Login = () => {
  const [ApiUrl, setApiUrl] = useState('')
  const [InputValue, setInputValue] = useState('')
  const [cookies, setCookie] = useCookies([""]);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {

      document.querySelector('.reset-btn').style.display = 'block';
      setSeconds('');

    }
  })


  const submitFormHandler = (e) => {
    e.preventDefault();
    var otp = document.querySelector('.otp_box').value

    const url = new URL(window.api_url + "/" + ApiUrl);
    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "phone": InputValue,
        "email": InputValue,
        "otp": otp,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 'logged_in') {
          navigate('/')
          setCookie("sanctum_token", data.token);

        }

        console.log(data);
      });

  }

  const sendOtpHandlerInput = () => {
    var inputText = document.querySelector('.email_phone_input_container').value
    if (inputText === "") {
      document.querySelector('.send_otp_btn ').style.display = "none";
    }
    else {
      document.querySelector('.send_otp_btn ').style.display = "Block";
    }
  }

  const sendOtpHandler = () => {
    const inputText = document.querySelector('.email_phone_input_container').value

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneno = /^\d{10}$/;

    if (inputText.match(mailformat)) {

      const url = new URL(window.api_url + "/sendEmailOtp");
      fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "email": inputText,
        })
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "otp_sent") {
            document.querySelector('.otp_box').disabled = false;
            document.querySelector('.form_submit_btn').disabled = false;
            document.querySelector('.send_otp_btn').style.display = 'none';

            document.querySelector('.email_phone_input_container').disabled = true;
            setApiUrl('verifyEmailOtp')
            setInputValue(inputText)

          }
          // setIsLoading(false);
        });
      return true;
    }

    if (inputText.match(phoneno)) {
      document.querySelector('.otp_box').disabled = false;
      document.querySelector('.form_submit_btn').disabled = false;
      const url = new URL(window.api_url + "/sendPhoneOtp");
      fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "phone": inputText,
        })
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "otp_sent") {
            document.querySelector('.otp_box').disabled = false;
            document.querySelector('.form_submit_btn').disabled = false;
            document.querySelector('.send_otp_btn').style.display = 'none';
            document.querySelector('.email_phone_input_container').disabled = true;
            setApiUrl('verifyPhoneOtp')
            setInputValue(inputText)

          }
          // setIsLoading(false);
        });
      return true;
    }
    else {
      document.querySelector('.email_phone_input_container').style.borderColor = "red";
      return false;
    }

  }



  return (
    <Wrapper>
      <div className='container'>
        <div className=' singin_container'>
          <form onSubmit={submitFormHandler} className='my-form'>
            <h3>Sign In</h3>
            <div className="mb-3">
              <label>Email / Phone Number</label>
              <input
                type="text"
                className="form-control email_phone_input_container"
                placeholder="Enter email / Phone"
                onInput={sendOtpHandlerInput}
              />
              <span className='send_otp_btn p-1 btn btn-primary' onClick={sendOtpHandler} >Send OTP</span>
            </div>
            <p className='error_heading text-danger d-none'>{seconds ? <span>Resend OTP after {seconds} second</span> : <button className='btn btn-primary  reset-btn'>Resend otp</button>} </p>
            <div className="mb-3">
              <label>OTP</label>
              <input
                type="number"
                className="form-control otp_box"
                placeholder="Enter OTP"
                disabled
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary form_submit_btn" >
                Submit
              </button>
            </div>
          </form>
        </div>

      </div>
    </Wrapper>
  )
}

export default Login