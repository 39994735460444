import { Link } from "react-router-dom";


const MenulistTabbing = (props) => {
  const custom_url = window.relativeUrl.split("/");
  // console.log("left menu :" + custom_url);
  function clickFunction(key) {
    props.setMemberId(key)
  }

  return (
    <ul>
      {props.submenu.map((e) => (
        <li
          className={
            custom_url[3] === e.slug
              ? "active-left_menu left_menu"
              : "left_menu"
          }
          onClick={() => clickFunction(e.slug)}
          id='nav_link_hover'
        >
          <Link to={"../" + custom_url[1] + "/" + custom_url[2] + "/" + e.slug} > {e.name} </Link>
        </li>
      ))}
    </ul>
  );
};

export default MenulistTabbing;
