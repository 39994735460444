import React from "react";

const ContactCard = (props) => {
  console.log(props.contact_detail);
  return (
    <div className="col-lg-6 col-md-6 col-12 contact_card_main_col">
      <div
        className="contact_card_div"
        onClick={() => props.changeState(props.contact_detail)}
      >
        <div
          className={
            Number(props.contact_detail.h_flip) === 0
              ? "contact_card"
              : "contact_card md_h_flip"
          }
        >
          <div className="card">
            <div className="card-body contact_card_body">
              <div className="row contact_card_inner_row">
                <div className="col col-6 contact_card_left_div">
                  <div
                    className={
                      Number(props.contact_detail.h_flip) === 0
                        ? "img_div"
                        : "img_div md_h_flip"
                    }
                  >
                    <img
                      src={
                        window.api_storage_url +
                        "/images/uploads/" +
                        props.contact_detail.image
                      }
                      alt="ISA Member Contact"
                    />
                  </div>
                </div>
                <div className="arrow_icon"></div>
                <div className="col col-6 contact_card_right_div right_div">
                  <div
                    className={
                      Number(props.contact_detail.h_flip) === 0
                        ? ""
                        : "md_h_flip"
                    }
                  >
                    <h1>{props.contact_detail.name}</h1>
                    <h2 className="italic contact_card_desig">
                      {props.contact_detail.designation}
                    </h2>
                    {/* <button className="btn btn-primary"  onClick={() => props.changeState(props.contact_detail)}>Read More &nbsp;<i className="fa-solid fa-angle-right"></i></button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
