import { Link } from "react-router-dom";
import Wrapper from "../layouts/wrapper";
import React, { useState, useEffect } from "react";
import MemberBanner from "./member_banner";
import axios from 'axios';


const MemberAssociate = () => {
  const initial_data = {
    "id": 1,
    "created_at": "2022-02-24T17:01:01.000000Z",
    "updated_at": "2022-09-06T12:21:26.000000Z",
    "heading1": "Sahitya",
    "text1": "Indian Steel as the platform for Indian iron and steel industry shall facilitate addressable of issues, concerns and challenges common to its members with Government and other stakeholders within and outside India in compliance with all applicable laws and regulations, including but not limited to the competition laws in India and abroad, for the time being, in force.",
    "heading2": "Vision",
    "text2": "The VISION of Indian Steel Association is to “work towards transforming the Indian Steel Industry as a global leader acclaimed for its Quality, Productivity and Competitiveness, with focus on health, safety and environment, along with growing thrust on innovation through R&D, adopting an inclusive and collective approach”.",
    "heading3": "heading 3",
    "text3": "text 3",
    "banner_img": "ourmembers.jpg",
    "banner_text1": "Associate ",
    "banner_text2": "Members",
    "banner_description": "",
    "status": "1"
  };
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    // console.log(params);
  }
  const guestSubmenu = [
    {
      id: 1,
      menu: "Executive Members",
      to: "/members/MemberExecutive",
    },
    {
      id: 2,
      menu: "Affiliate Members",
      to: "/members/MemberAffiliate",
    },
    {
      id: 3,
      menu: "Associate Members",
      to: "/members/MemberAssociate",
    }
  ];
  const extranetSubmenu = [...guestSubmenu, { menu: '*documentation', to: '/about/docs' }, { menu: '*Former Secretary-Generals', to: 'about/general-secretary' }];

  const [getApi, setGetApi] = useState([]);
  const [memberInfo, setMemberInfo] = useState({ description: null, open: false });


  const getApiData = async () => {
    const res = await axios.get(window.api_url + '/fatch_members');
    setGetApi(res.data);
    console.log(res);
  }

  const getMemberInfo = async (id) => {
    const res = await axios.get(window.api_url + '/fatch_members_id/' + id);
    if (res.status === 200){
      setMemberInfo({ ...memberInfo, ...res.data[0], open: true });
      console.log(res.data[0]);
    }  
  }


  useEffect(() => {
    getApiData();
  }, []);

  const mystyle = {
    width: '100% !important'
  }
  return (
    <>
      <Wrapper getUserData={getUserData} menu="Members" submenu={UserData.name ? extranetSubmenu : guestSubmenu}>
        <MemberBanner data={initial_data} />

        <div className="container">
          <div className="row my-4 mx-1">
            <h1>Associate Members</h1>
          </div>
          <div className="row my-2 mx-1 description_box">

            <p>
              <div className="">
                <table className="table table-bordered" style={mystyle}>
                  <thead>
                    <th></th>
                    <th></th>
                    <th>Represented by</th>
                    <th>Designation</th>
                  </thead>
                  <tbody>
                    {getApi.map((items) => {
                      return (
                        items.member_type_id == "3" ?
                          <tr className="" key={items.id}>
                            <td scope="row">
                              <Link
                                to={"/members/associate/" + items.slug} className="nav-link">
                                <img src={items.logo ? window.api_storage_url + '/images/uploads/' + items.logo : 'https://ui-avatars.com/api/?name=' + items.name} width="40px" />
                              </Link>
                            </td>
                            <td scope="row">
                              <h4>
                                <Link
                                  to={"/members/associate/" + items.slug} className="nav-link">
                                  {items.company_name}
                                </Link>
                              </h4>
                            </td>
                            <td scope="row" className="link" data-toggle="modal" data-target="#exampleModal" onClick={() => getMemberInfo(items.id)}><h4>{items.person_name}</h4></td>
                            <td scope="row"><h4>{items.person_post}</h4></td>

                          </tr> : null
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </p>
          </div>
          <div className="row member_types">
            <div className="col col-lg-4 col-md-12">
              <Link
                to="/members/MemberExecutive"
                className="nav-link">
                Executive Members
              </Link>
            </div>
            <div className="col col-lg-4 col-md-12">
              <Link to="/members/MemberAffiliate" className="nav-link">
                Affiliate Members
              </Link>
            </div>
            <div className="col col-lg-4 col-md-12">
              <Link to="/members/MemberAssociate" className="nav-link">
                Associate Members
              </Link>
            </div>
            
          </div>
        </div>
      </Wrapper >

      {/* Modals */}
      <React.Fragment>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content p-3" style={{minWidth:'800px',backgroundColor:"var(--color14)",color:"white"}}>
              <div class="modal-body">
              <button class="btn btn-outline-light rounded-circle mb-2 float-right" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                      <img src={window.api_storage_url + "/images/uploads/"+memberInfo?.image} alt={memberInfo?.person_name} />
                      <h3 className="text-white">{memberInfo?.person_name}</h3>
                      <p>{memberInfo?.person_post}</p>
                  </div>
                  <div className="col-lg-8 col-lg-7">
                      <div dangerouslySetInnerHTML={{__html : memberInfo?.description}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default MemberAssociate;
