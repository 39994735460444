import React, { useEffect, useState } from "react";

const About_contact = () => {
  const [settings, setSetting] = useState("");
  //const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/settings");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setSetting(data[0]);
        //setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="container-contact container py-8 my-4 px-28">
      <h1 className="">Contact Us</h1>
      <p className="py-2">
        {" "}
        <b> Have some queries? </b> <br /> Feel free to call or email us. We
        shall be happy to help.
      </p>
      <div className="row pt-8 container_contact_row">
        <div className="col-lg-5 col-md-4 col-sm-10 text-left contact_us_social">
          <li className="py-2">
            {" "}
            <a href="tel:011 – 42668800">
              <i className="fa-solid fa-phone"></i> &nbsp;011–42668800
            </a>{" "}
          </li>
          <li className="py-2">
            {" "}
            <a href="mailto:media.isa@indsteel.org">
              <i className="fa-solid fa-envelope"></i> &nbsp;media.isa@indsteel.org
            </a>{" "}
          </li>
          <li className="py-2">
            {" "}
            <a href="mailto:media.isa@indsteel.org">
              <i className="fa-solid fa-envelope"></i> &nbsp;secretary.general@indsteel.org
            </a>{" "}
          </li>
          <li className="py-2">
            {" "}
            <a href="tell:011 – 42668805">
              <i className="fa-solid fa-fax"></i> &nbsp;011–42668805
            </a>{" "}
          </li>
          {/* <div className="social_media_contact">
            <ul className="d-flex py-4">
              <li className="px-2">
                <a href={settings.facebook} target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fa-brands fa-facebook-square text-2xl"></i>
                </a>
              </li>
              <li className="px-2">
                <a href={settings.twitter} target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fa-brands fa-twitter-square text-2xl"></i>
                </a>
              </li>
              <li className="px-2">
                <a href={settings.instagram} target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fa-brands fa-instagram-square text-2xl"></i>
                </a>
              </li>
              <li className="px-2">
                <a href={settings.linkedin} target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fa-brands fa-linkedin text-2xl"></i>
                </a>
              </li>
              <li className="px-2 d-none">
                <a href={settings.facebook} target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fa-brands fa-youtube-square text-2xl"></i>
                </a>
              </li>
            </ul>
          </div> */}
          {/* SOCIAL MEDIA CONNECTS */}
        </div>
        <div className="col-lg-5 col-md-4 col-sm-10 text-left w-100 about_contact_map">
          <iframe
            title="office_adress"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9891599994653!2d77.2233140150363!3d28.63008698241872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd33c9f50295%3A0x7f74864d855f5a61!2sIndian%20Steel%20Association!5e0!3m2!1sen!2sin!4v1658485150160!5m2!1sen!2sin"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default About_contact;
