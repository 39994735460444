import React from "react";

function Contact(props) {
  const contactData = props.contactData;
  return (

    <div className="contact_detail col col-lg-6 col-12 px-4 py-0">
          <div className="row bg15">
            <div className="col col-12">
              <div className="row ">
                <div className="col col-12 p-0 px-4">
                  <h1 className="text-left text-2xl pt-4 pb-2 text-light managment-name">
                    {contactData.name}
                  </h1>
                  <p className="text-left italic text-light pb-4">
                  {contactData.designation}
                  </p>
                </div>
              </div>
              <div className="row py-2" id="btn-container">
                <div className="col col-5 p-0">
                  <div className="row mx-3">
                    <div className="col col-12 mb-3 p-0">
                      <a
                        href={"tel:" + contactData.phone}
                        className="btn btn-contact btn-light"
                      >
                        <i className="fa fa-phone-square" /> | {contactData.phone}
                      </a>
                    </div>
                  
                  </div>
                </div>

                <div className="col col-7 p-0">
                  <div className="row mx-3">
                    <div className="col col-12 mb-3 p-0">
                      <a
                        href={"mailto:" + contactData.email}
                        className="btn btn-contact btn-light"
                      >
                        <i className="fa fa-envelope" /> | {contactData.email}
                      </a>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
  );
}

export default Contact;
