import React, { useEffect, useState } from "react";
import ContactDiv from "../right_div/contact_div";
import Banner from "./vision_banner";
import Loading from "../shared/loading";


const ApexMembers = (props) => {
  const [committee_contacts, setcommittee_contacts] = useState([]);
  const [bannerData, setbannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const href = window.relativeUrl;

  useEffect(() => {
    setIsLoading(true);
    const url = new URL(
      window.api_url + "/committeeDetail?href=/committees/apexCommittee"
    );
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setcommittee_contacts(data.committee_contacts);
        setbannerData(data.committee);
        console.log("this is my good");
        console.log(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [href]);

  return (
    <section>
      <Banner data={bannerData} />
      <div className="container our-team-container">
        <div className="row pt-3 pb-8">
          {isLoading ? (
            <Loading />
          ) : (
            <ContactDiv contact_detail={committee_contacts} key={committee_contacts.id} />
          )}
        </div>
      </div>
    </section>
  );
};

export default ApexMembers;
