import Wrapper from "../layouts/wrapper";
import Vision from "./components/about_page/vision";
import Contact from './components/about_page/about_contact'
import Team from './components/about_page/our_team'
import Brochure from "./components/about_page/about_brochure";
import ApexMembers from "./components/about_page/apex_members";
import { useEffect, useState } from "react";
import GlobalOutreach from "./components/about_page/global_outreach";
import Banner from "./member_banner";

const Policy = () => {
    const [policy, setPolicy] = useState({});

    useEffect(() => {
        const url = new URL(window.api_url + "/cms/privacy-policy");
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        fetch(url, {
            method: "GET",
            headers,
        }).then((response) => response.json())
            .then((data) => {
                setPolicy(data?.data)
                console.log(data?.data)
            }).catch((error) => {
                console.error("Error:", error);
            });

    }, []);
    return (
        <Wrapper getUserData={{}} menu="Policy" submenu={[]}>
            <Banner data={{
                banner_text1: policy?.title,
                banner_text2: policy?.sub_title,
                banner_description: "",
                banner_img: "63173b461b460.jpg"
            }} />
            <div className="container py-4 vision_container">
                <h1 className="text-center text-3xl color11">{policy?.title}</h1>
                <p className="py-4 text-xl text-left vision_para" dangerouslySetInnerHTML={{ __html: policy?.short_description }} />
            </div>
        </Wrapper>
    );
};

export default Policy;
