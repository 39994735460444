import React, { useState, useEffect } from "react";
import "../about_page/about.css";
import AboutBanner from "./vision_banner";
import ContactDiv from "../right_div/contact_div";

function Leadership() {
    const [committee_contacts, setcommittee_contacts] = useState({
        data: { banner_img: "", banner_text1: "", banner_text2: "", banner_description: "" },
        members: []
    });

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        const urlComitte = new URL(window.api_url + "/isa-leadership");
        fetch(urlComitte, { method: "GET", headers, })
            .then((response) => response.json())
            .then((data) => {
                let tmp = []
                data?.member?.map(item => {
                    tmp.push({
                        "id": 1,
                        "created_at": item?.created_at,
                        "updated_at": item?.updated_at,
                        "committee_id": item?.committee_contact_id,
                        "image": item?.logo,
                        "name": item?.person_name,
                        "designation": item?.slug,
                        "department": item?.person_post,
                        "description": item?.description,
                        "status": item?.status,
                        "h_flip": item?.member_type_id
                    })
                })
                setcommittee_contacts({
                    ...committee_contacts,
                    data: data?.data,
                    members: tmp
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    return (
        <section>
            <AboutBanner data={committee_contacts?.data} />
            <div className="container py-8 mission_container">
                <h1 className="text-3xl py-2 color11">ISA Leadership</h1>
                <div className="row p-0 py-2 px-3">
                    {committee_contacts?.members?.length > 0 && <ContactDiv contact_detail={committee_contacts?.members} />}
                </div>
            </div>
        </section>
    );
}

export default Leadership;
