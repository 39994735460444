import { useState } from "react";
import ContactDetails from "../shared/contact_details";
import ContactCard from "./contact_card";

const ContactDiv = (props) => {
  const [activeDetail, setactiveDetail] = useState(props.contact_detail[0]);
  function changeState(params) {
    setactiveDetail(params);
    document.getElementById("contact_detail").classList.remove("d-none");
  }
  return (
    <>
      {props.contact_detail.map((e, i) => {
        if (!props?.max) {
          return <ContactCard key={e.id} contact_detail={e} changeState={changeState} />
        }
        else if (props?.max && props?.max > i) {
          return <ContactCard key={e.id} contact_detail={e} changeState={changeState} />
        }
      })}

      <ContactDetails
        contact_detail={props?.max ? props?.contact_detail?.slice(0, props?.max) : props.contact_detail}
        activeDetail={activeDetail}
        changeState={changeState}
      />
    </>
  );
};

export default ContactDiv;
