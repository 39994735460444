import React, { useEffect, useState } from "react";

import MainBlock from "../block1";
import MainBlockLoading from "../loading_block1";

const NotificationList = (props) => {
  const [state, setstate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const url = new URL(
      window.api_url +
      "/govtNotifications?notification_type=" +
      props.notification_type
    );
    console.log("nofitication :" + url);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <MainBlockLoading />
      ) : (
        <>
          {state.map((e) => (
            <>
              {!props.FilteredData.filteredYear &&
                !props.FilteredData.filteredMonth
                ? <MainBlock block_detail={e} />
                :
                <>
                  {!props.FilteredData.filteredMonth ? (
                    <>
                      {e.date.split("-")[0] ==
                        props.FilteredData.filteredYear ? (
                        <>
                          <MainBlock
                            block_detail={e}
                            FilteredData={props.FilteredData}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {e.date.split("-")[0] == props.FilteredData.filteredYear &&
                    e.date.split("-")[1] == props.FilteredData.filteredMonth ? (
                    <>
                      <MainBlock
                        block_detail={e}
                        FilteredData={props.FilteredData}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              }

            </>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default NotificationList;
