import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const PageHeading = (props) => {


  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 568px)").matches
  )


  useEffect(() => {
    window
      .matchMedia("(min-width: 568px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);


  return (
    <div className="container">
      <div className="page_heading">
        <Link to="/">

          {matches && (<span>  Home &#62;{props.l1} </span>)}
          {!matches && ('')}
        </Link>
        <span className="italic py-4">{props.l2} </span>
      </div>
    </div>
  );
};
export default PageHeading;
