const SignupBox = () => {
  return (
    <div className="row p-1 mt-3 text-left pr-2">
      <div className="signup_box col-12">
        <h3> Sign up for news from Indian Steel Association </h3> <br />
        <form>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="you@email.com"
            />
            <small id="emailHelp" className="form-text sign_up_muted">
              We never share your email ID with anyone else. You can unsubscribe
              at any time using the in our emails.
            </small>
          </div>
          <button type="submit" className="btn btn-primary">
            {" "}
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};
export default SignupBox;
