import { Link } from "react-router-dom";
import PageHeading from "../components/page_heding";
import "./govtmenu.css"

const SubHeadingMenu = (props) => {
  const custom_url = window.relativeUrl.split("/");
  return (
    <div className="sub_heading_menu container-fluid">
      <div className="container">
        <div className="row">
          <div className="col col-2">
            <div className="submenu">
              <button className="btn btn-nobg p-3">
                <PageHeading l1={props.menu} />
              </button>
            </div>
          </div>
          <div className="col col-10 mycol-10">
            {/* <div className="submenu">
              {props.submenu.map((e) => (
                <span key={e.id}>
                  {e.to.toLowerCase().startsWith("http", 0)
                    ? <a href={e.to} target="_blank" rel="noreferrer" className="btn btn-fh p-3">{e.menu} </a>
                    : <Link key={e.menu} to={e.to} className={custom_url[2] === e.to.split("/")[2] ? "active-fh btn btn-fh p-3" : "btn btn-fh p-3"}>
                      {e.menu}{" "}
                    </Link>}
                </span>

              ))}
            </div> */}

            {/* <div className="submenu"> */}
            <ul id='menu_my'>
              <li><a class='prett' href='#' title='Menu'>Trade</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/ForeignTradePolicy">Foreign Trade Policy/Act</a></li>
                  <li><a href="/govtNotifications/ForeignTradeAgreements">FTA/CEPA/CETA/RTA/Other Foreign Trade Agr.</a></li>
                  <li><a href="/govtNotifications/SIMS">SIMS</a></li>
                  <li><a href="/govtNotifications/QualityControlOrders">Quality Control Orders</a></li>
                  <li><a href="/govtNotifications/NonTariffMeasures">Customers/Tariff/Non Tariff Measures</a></li>
                  <li><a href="/govtNotifications/PromotionRD">Promotion/R&D</a></li>
                  {/* <li><a href="/govtNotifications/TechnicalCommitteeReports">Tech. Committee Reports</a></li> */}
                </ul>
              </li>
              <li><a class='prett' href='#' title='Menu'>Environment</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/Air">Air</a></li>
                  <li> <a href="/govtNotifications/Water">Water </a></li>
                  <li> <a href="/govtNotifications/NoiseSound">Noise/Sound</a></li>
                  <li> <a href="/govtNotifications/IndustrialNorms">Industrial Norms</a></li>
                  <li> <a href="/govtNotifications/SolidWasteDisposal">Solid Waste Disposal</a></li>
                  <li> <a href="/govtNotifications/EnvironmentImpactAssessment">Environment Impact Assessment(EIA)</a></li>
                  <li> <a href="/govtNotifications/Sustainability">Sustainability</a></li>
                  {/* <li> <a href="/govtNotifications/"></a></li> */}

                  {/* <li> <a href="/govtNotifications/EIASustainability">EIA Sustainability</a></li> */}
                </ul>
              </li>
              <li><a class='prett' href='#' title='Menu'>Sustainability</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/HydrogenPolicy">Hydrogen Policy</a></li>
                  <li><a href="/govtNotifications/PolicyonGfreening">Policy on Greening</a></li>
                </ul>
              </li>
              <li><a class='prett' href='#' title='Menu'>Power</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/ElectricityRules">Electricity Rules</a> </li>
                  <li> <a href="/govtNotifications/ElectricityDuty">Electricity Duty</a></li>
                  <li> <a href="/govtNotifications/RenewableEnergy">Renewable Energy</a></li>
                </ul>
              </li>
              <li><a class='prett' href='#' title='Menu'>Other</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/OtherNotifications">Notifications</a> </li>
                  <li> <a href="/govtNotifications/OtherGuidelines">Guidelines</a></li>
                </ul>
              </li>
              <li><a class='prett' href='#' title='Menu'>Railway</a>
                <ul class='menus_my'>
                  <li> <a href="/govtNotifications/RailwayActs">Acts</a> </li>
                  <li> <a href="/govtNotifications/RailwayRules">Rules</a> </li>
                  <li> <a href="/govtNotifications/RailwayGuidelines">Guidelines</a> </li>
                  <li> <a href="/govtNotifications/RailwayAmendments">Amendments</a> </li>
                  <li> <a href="/govtNotifications/RailwayOther">Other</a> </li>
                </ul>
              </li>

              <li><a class='prett' href='#' title='Menu'>Raw Materials</a>
                <ul class='menus_my'>
                  <li class='has-submenu_my'><a class='prett' href='Dropdown 1' title='Dropdown 1'>
                    Coal</a>
                    <ul class='submenu_my'>
                      <li><a href="/govtNotifications/CoalActs" title="Sub Menu">Acts</a></li>
                      <li><a href="/govtNotifications/CoalRules" title="Sub Menu">Rules</a></li>
                      <li><a href="/govtNotifications/CoalAmendments" title="Sub Menu">Amendments</a></li>
                    </ul>
                  </li>
                  <li class='has-submenu_my'><a class='prett' href='Dropdown 1' title='Dropdown 1'>
                    Iron Ore</a>
                    <ul class='submenu_my'>
                      <li><a href="/govtNotifications/IronOreActs" title="Sub Menu">Acts</a></li>
                      <li><a href="/govtNotifications/IronOreRules" title="Sub Menu">Rules</a></li>
                      <li><a href="/govtNotifications/IronOreAmendments" title="Sub Menu">Amendments</a></li>
                    </ul>
                  </li>
                  <li class='has-submenu_my'><a class='prett' href='Dropdown 1' title='Dropdown 1'>
                    Mines & Mineral</a>
                    <ul class='submenu_my'>
                      <li><a href="/govtNotifications/ActsMinesMineral" title="Sub Menu">Acts</a></li>
                      <li><a href="/govtNotifications/RulesMinesMineral" title="Sub Menu">Rules</a></li>
                      <li><a href="/govtNotifications/AmendmentsMinesMineral" title="Sub Menu">Amendments</a></li>
                    </ul>
                  </li>
                  <li class='has-submenu_my'><a class='prett' href='Dropdown 1' title='Dropdown 1'>
                    Others</a>
                    <ul class='submenu_my'>
                      <li><a href="/govtNotifications/OthersActs" title="Sub Menu">Acts</a></li>
                      <li><a href="/govtNotifications/OthersRules" title="Sub Menu">Rules</a></li>
                      <li><a href="/govtNotifications/OthersAmendments" title="Sub Menu">Amendments</a></li>
                    </ul>
                  </li>
                </ul>
              </li>

            </ul>

            {/* </div> */}

          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

export default SubHeadingMenu;
