
import React, { useEffect, useState } from 'react'
import ReactOwlCarousel from 'react-owl-carousel';


function Banner(props) {
  return (
    <section>
      <div className='container-fluid'>
        <div className='container '>
          <div className='row'>
            <div className='col col-12 px-0 banner_image_div'>
              <div className='banne_div_img'>
                <img src={window.api_storage_url + "/images/sliders/" + props.data.banner_img} alt="home_banner" />
              </div>

              {/* <button className="btn btn-outline-light float-left mx-8 mt-4">Hybrid Event</button> */}
              <div id='left_container' className="left_container">

                <div className="vison_top_heading_container text-left">
                  <div className='row flex-col mx-4 text-left color11 mt-16'>
                    <div className='col-lg-12 col-sm-10 bg-light vison_heading1'>
                      <h1 className='' id='heading_vision'> {props.data.banner_text1}</h1>
                    </div>
                    <div className='col-12 bg-light my-2 vison_heading1'>
                      <h2>{props.data.banner_text2}</h2>
                    </div>
                    <p className='text-light py-4 text-xl vison_top_para'>
                      {props.data.banner_description}
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>

  )
}





export default Banner