import React from "react";

import MainBlock from "../block1";

const AnualReport = () => {

  const data = [
    {
      "id": 1,
      "report_type_id": "3",
      "created_at": "2022-07-02T10:48:27.000000Z",
      "updated_at": "2022-07-02T10:48:27.000000Z",
      "status": "1",
      "date": "2017-10-01",
      "heading": "Annual Report 2019-2020",
      "description": "ISA brings together the who's who of the Indian steel industry on a common plaorm and drives the growth of the sector through innovaon and collecve approach. Being an industry body, the Associaon comprises both Full Members and Affiliate Members.",
      "report_number": null,
      "year": null,
      "filter": null,
      "report_attachment": "indian-steel-association-annual-report-2019-20.pdf"
  },
  {
    "id": 2,
    "report_type_id": "3",
    "created_at": "2022-07-02T10:48:27.000000Z",
    "updated_at": "2022-07-02T10:48:27.000000Z",
    "status": "1",
    "date": "2017-10-01",
    "heading": "Annual Report 2020-2021",
    "description": "The Department of Commerce (the Department)\r\npreliminarily determines that countervailable subsidies\r\nare being provided to producers and exporters of\r\ncarbon and alloy steel wire rod (wire rod) from Italy.",
    "report_number": null,
    "year": null,
    "filter": null,
    "report_attachment": "7th-Annual-General-Meeting-Of-ISA.pdf"
}
  ]
  return (
    <React.Fragment>
      <MainBlock block_detail={data[0]} />
      <MainBlock block_detail={data[1]} />
    </React.Fragment>
  );
};

  

export default AnualReport;
