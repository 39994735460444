import React, { useEffect, useState } from "react";
import Loading from "../shared/loading";
import MainBlockLoading from "../loading_block1";

const CsrDetail = (props) => {
  const csrId = props.csrId;
  const [csrDetail, setcsrDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const custom_url = window.relativeUrl.split("/");
  console.log(custom_url[3]);
  useEffect(() => {
    setIsLoading(true);
    const url = new URL(window.api_url + "/csrDetail?slug=" + custom_url[3]);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setcsrDetail(data.csr);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [csrId]);

  console.log(csrDetail);
  return (
    <React.Fragment>
      {isLoading ? (
        <MainBlockLoading />
      ) : (
        <>
          { csrDetail ? 
          <div className="row affiliate_csr">
          <div className="col col-12 border-left">
            <h1 className="my-4"> {csrDetail.name}</h1>

            <div
              dangerouslySetInnerHTML={{
                __html: csrDetail.long_description,
              }}
            />
            {/* <button className="btn btn-primary my-4 csr_btn">
              <a href={csrDetail.url} rel="noreferrer" target="_blank">
                CLICK HERE TO VISIT {csrDetail.name} WEBSITE{" "}
                <span className="pl-4">
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </a>
            </button> */}
          </div>
        </div>
        :""}
        </>
      )}
    </React.Fragment>
  );
};

export default CsrDetail;
