import React, { useEffect, useState } from 'react'
import MainBlockLoading from "../loading_block1"
import CourseBlock from "../course_block"
import CalenderYear from '../calender_year'
import FilterBlock from '../filter_block'
import PageHeading from '../page_heding'
import InnerWrapper from '../wrapper_3_9'


const Steel_technology = () => {
    return (
        <>

            {/* <PageHeading h1="steel Technology"/> */}
            <InnerWrapper col3={<Col3 />} col9={<Col9 />} />

        </>
    )
}

const year = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011]
const filter = ["Overview of the course", "Learning 1 -Steel Everywhere", "Learning 2 -The Ages of Civilisation",
    "Learning 3 -Turning Rocks into Metals", "Learning 4 -Properties Metals TENSTEEL", "Learning 5 -Metals Crystals",
    "Learning 6 -Alloy TENSTEEL", "Learning 7 -Summary Of Steel The Material", "Learning 8 -The Perfect Construction Material",
    "Learning 9 -Smelting", "Learning 10 -Heat Treatment", "Learning 11 -Thermomechanical Process", "Learning 12 -Making Car from steel",
    "Learning 13 -Advance High Strenght Steel", "Learning 14 -Sustainability", "Learning 15 -The Future of steel"]

const Col3 = () => {
    return (
        <>
            <FilterBlock filter={filter} />
            <CalenderYear year={year} />
        </>
    )
}

const Col9 = () => {
    const [state, setstate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const url = new URL(window.api_url + "/govtNotifications");


        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        fetch(url, {
            method: "GET",
            headers,
        })
            .then(response => response.json()).then(data => {
                setstate(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <React.Fragment>
            {isLoading
                ? <MainBlockLoading />
                : state.map(e => <CourseBlock key={e.id} block_detail={e} />)
            }

        </React.Fragment>
    )
}

export default Steel_technology