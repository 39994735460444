import { Link } from "react-router-dom";
import PageHeading from "../components/page_heding";
const SubHeadingMenu = (props) => {

  const custom_url = window.relativeUrl.split("/");
  return (
    <div className="sub_heading_menu container-fluid">
      <div className="container">
        <div className="row">
          <div className="col col-2">
            <div className="submenu">
              <button className="btn btn-nobg p-3">
                <PageHeading l1={props.menu} />
              </button>
            </div>
          </div>
          <div className="text-right col col-10">
            <div className="submenu">
              {props.submenu.map((e) => (

                <span key={e.id}>
                  {e.to.toLowerCase().startsWith("http", 0)
                    ? <a href={e.to} target="_blank" rel="noreferrer" className="btn btn-fh p-3">{e.menu} </a>
                    : <Link key={e.menu} to={e.to} className={custom_url[2] === e.to.split("/")[2] ? "active-fh btn btn-fh p-3" : "btn btn-fh p-3"}>
                      {e.menu}{" "}
                    </Link>}
                </span>

              ))}
            </div>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

export default SubHeadingMenu;
