import React, { useEffect, useState } from "react";
import MainBlock from "../block1";
import MainBlockLoading from "../loading_block1";
import CalenderYear from "../calender_year";
import FilterBlock from "../filter_block";
import InnerWrapper from "../wrapper_3_9";
import PageHeading from "../page_heding";
import CalenderMonth from "../calender_month";
import SignupBox from "../signup_box";

const IsaInsight = () => {
  const [FilteredYear, setFilteredYear] = useState();
  const [FilteredMonth, setFilteredMonth] = useState("");
  const [Filter1, setFiltered1] = useState();
  const [Filter11, setFiltered11] = useState();

  function yearFilter(params) {
    setFilteredYear(params);
  }
  function monthFilter(params) {
    setFilteredMonth(params);
  }
  function filterFunction(params1, params2) {
    setFiltered1(params1);
    setFiltered11(params2);
  }
  const FilteredData = {
    filteredYear: FilteredYear,
    filteredMonth: FilteredMonth,
    filter1: Filter1,
    filter11: Filter11,
  };
  return (
    <>
      {/* <PageHeading h1="Isa Insight" /> */}
      <InnerWrapper
        col3={
          <Col3
            yearFilter={yearFilter}
            monthFilter={monthFilter}
            filterFunction={filterFunction}
            FilteredData={FilteredData}
          />
        }
        col9={<Col9 FilteredData={FilteredData} />}
      />
    </>
  );
};

const filter = ["Blogs", "Articles", "Podcast"];
const year = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
];
const month = [
  { id: "01", name: "Jan" },
  { id: "02", name: "Feb" },
  { id: "03", name: "Mar" },
  { id: "04", name: "Apr" },
  { id: "05", name: "May" },
  { id: "06", name: "Jun" },
  { id: "07", name: "Jul" },
  { id: "08", name: "Aug" },
  { id: "09", name: "Sep" },
  { id: "10", name: "Oct" },
  { id: "11", name: "Nov" },
  { id: "12", name: "Dec" },
];
const Col3 = (props) => {
  return (
    <>
      {/* <FilterBlock filter1={filter} FilteredData={props.FilteredData} /> */}
      <CalenderYear
        year={year}
        yearFilter={props.yearFilter}
        FilteredData={props.FilteredData}
      />
      <CalenderMonth
        month={month}
        monthFilter={props.monthFilter}
        FilteredData={props.FilteredData}
      />
      <SignupBox />
    </>
  );
};

const Col9 = (props) => {
  const [state, setstate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.api_url + "/insights");
    console.log("api:" + url);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setstate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <MainBlockLoading />
      ) : (
        <>
          {state.map((e) => (
            <>
              {e.heading === "Podcast" ? (
                <>
                  {!props.FilteredData.filteredYear &&
                    !props.FilteredData.filteredMonth ? (
                    <MainBlock block_detail={e} />
                  ) : (
                    <>
                      {!props.FilteredData.filteredMonth ? (
                        <>
                          {e.date.split("-")[0] ==
                            props.FilteredData.filteredYear ? (
                            <>
                              <MainBlock
                                block_detail={e}
                                FilteredData={props.FilteredData}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {e.date.split("-")[0] ==
                        props.FilteredData.filteredYear &&
                        e.date.split("-")[1] ==
                        props.FilteredData.filteredMonth ? (
                        <>
                          <MainBlock
                            block_detail={e}
                            FilteredData={props.FilteredData}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!props.FilteredData.filteredYear &&
                    !props.FilteredData.filteredMonth ? (
                    <MainBlock block_detail={e} />
                  ) : (
                    <>
                      {!props.FilteredData.filteredMonth ? (
                        <>
                          {e.date.split("-")[0] ==
                            props.FilteredData.filteredYear ? (
                            <>
                              <MainBlock
                                block_detail={e}
                                FilteredData={props.FilteredData}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {e.date.split("-")[0] ==
                        props.FilteredData.filteredYear &&
                        e.date.split("-")[1] ==
                        props.FilteredData.filteredMonth ? (
                        <>
                          <MainBlock
                            block_detail={e}
                            FilteredData={props.FilteredData}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default IsaInsight;
