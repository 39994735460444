import ReactOwlCarousel from "react-owl-carousel";

const ContactDetails = (props) => {
  return (
    <div id="contact_detail" className="container bg14 text-light py-4 d-none">
      <div className="row float-right cursor-pointer m-2 contact_details_close_btn">
        <button
          onClick={closeFunction}
          className="btn btn-outline-light rounded-circle"
        >
          x
        </button>
      </div>
      <div className="row px-12 py-4 scrollbar_row_container">
        <div className="col-lg-4 col-md-6 col-sm-12 h-100 scrollbar_main_img_col">
          <img className=""
            src={
              window.api_storage_url +
              "/images/uploads/" +
              props.activeDetail.image
            }
            alt="ISA Member Contact"
          />
          <h3 className="text-left text-light">
            {props.activeDetail.name}
          </h3>
          <p className="text-left">{props.activeDetail.designation}</p>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <div className="scrollbar_container" dangerouslySetInnerHTML={{ __html: props.activeDetail.description }} />
            
        </div>
        <hr className="w-100 my-2" />
        <div className="container">
          <div className="row">
            <ReactOwlCarousel
              className="owl-theme"
              items="5"
              navText={["<", ">"]}
              navClass={['d_none_important','d_none_important',]}
              loop ={false}
              autoplay={false}
              nav
              responsive={{
                0:{
                    items:2,
                    margin: 2
                },
                600:{
                    items:2,
                    margin: 2
                },
                1000:{
                    items:5,
                }}
            }
            >
              {props.contact_detail.map((e) => (
                <div className=" col-12 mx-1" >
                  <div className="contact_card_carousel_img" onClick={() => props.changeState(e)}>
                  <img
                    src={window.api_storage_url + "/images/uploads/" + e.image}
                    alt="ISA Member Contact"
                  />

                  </div>
                  {e.name}
                </div>
              ))}
            </ReactOwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

function closeFunction() {
  document.getElementById("contact_detail").classList.add("d-none");
}


export default ContactDetails;
