import { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import logo1 from '../../../assets/podcast1.jpg';
import axios from 'axios';

const Podcast = () => {

  const [state, setstate] = useState([]);
  const url = new URL(window.api_url + "/podcast_latest");
  // console.log("podcast:" + url);
  useEffect(() => {
    fetch(url).then((result) => {
      result.json().then((response) => {
        // console.warn("resutl", response)
        setstate(response);
      }
      )

    });
  }, []);
  return (
    <>
      <a href="/knowledgeBank/podcast" target={"_blank"}>
        <div className="card insight_card my-2" id="podcast_container">
          <img src="https://admin.indsteel.org/assets/images/podcast.jpg" className="img-fluid rounded-top" alt="" style={{ height: '450px' }} />

          {/* <div className="podcast_bottom">
          <iframe src={state.url} height="100%" width="400px" frameBorder="0" scrolling="no"></iframe>
        </div> */}
        </div>
      </a>
    </>
  )
}

export default Podcast;
