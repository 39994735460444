import React, { useEffect, useState } from "react";
import MenulistTabbing from "../left_div/menu_list_tabbing";
import CsrDetail from "../right_div/csr_dedail";


import InnerWrapper from "../wrapper_3_9";
import MainBlockLoading from "../loading_block1"

const Csr = () => {
const custom_url = window.relativeUrl.split("/");
const [csrId, setcsrId] = useState(custom_url[3]);
  const [menu, setmenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
      setIsLoading(true)
      const url = new URL(window.api_url + "/csr");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      setmenu(data);
      setIsLoading(false)
    })
      .catch((error) => {
        console.error("Error:", error);
      });
      
  }, []);


  return (
    <React.Fragment>
      {
        isLoading ? <MainBlockLoading/> :  <InnerWrapper
        col3={<MenulistTabbing submenu={menu} setMemberId={getMemberId} csrId={csrId} />}
        col9={<CsrDetail csrId={csrId} />}
      />
      }
     
    </React.Fragment>
  );

  function getMemberId(params) {
    setcsrId(params);
  }
};


export default Csr;
