import { Link } from "react-router-dom";
import Wrapper from "../layouts/wrapper";
import { useState, useEffect } from "react";
import MemberBanner from "./member_banner";
import axios from 'axios';
const OurMembersHome = () => {
  const initial_data = {
    "id": 1,
    "created_at": "2022-02-24T17:01:01.000000Z",
    "updated_at": "2022-09-06T12:21:26.000000Z",
    "heading1": "Sahitya",
    "text1": "Indian Steel as the platform for Indian iron and steel industry shall facilitate addressable of issues, concerns and challenges common to its members with Government and other stakeholders within and outside India in compliance with all applicable laws and regulations, including but not limited to the competition laws in India and abroad, for the time being, in force.",
    "heading2": "Vision",
    "text2": "The VISION of Indian Steel Association is to “work towards transforming the Indian Steel Industry as a global leader acclaimed for its Quality, Productivity and Competitiveness, with focus on health, safety and environment, along with growing thrust on innovation through R&D, adopting an inclusive and collective approach”.",
    "heading3": "heading 3",
    "text3": "text 3",
    "banner_img": "ourmembers.jpg",
    "banner_text1": "Our ",
    "banner_text2": "Members",
    "banner_description": "",
    "status": "1"
  };
  const [UserData, setUserData] = useState([]);
  const getUserData = (params) => {
    setUserData(params)
    // console.log(params);
  }

  const guestSubmenu = [
    {
      id: 1,
      menu: "Executive Members",
      to: "/members/MemberExecutive",
    },
    {
      id: 2,
      menu: "Affiliate Members",
      to: "/members/MemberAffiliate",
    },
    {
      id: 3,
      menu: "Associate Members",
      to: "/members/MemberAssociate",
    }
    
  ];
  const extranetSubmenu = [...guestSubmenu, { menu: '*documentation', to: '/about/docs' }, { menu: '*Former Secretary-Generals', to: 'about/general-secretary' }];


  const [getApi, setGetApi] = useState([]);

  const getApiData = async () => {
    const res = await axios.get(window.api_url + '/member_text');
    setGetApi(res.data);
    console.log(res);
  }

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <Wrapper getUserData={getUserData} menu="Members" submenu={UserData.name ? extranetSubmenu : guestSubmenu}>
        <MemberBanner data={initial_data} />
        <div className="container">
          <div className="row my-4 mx-1">
            <h1> Our Members</h1>
          </div>
          <div className="row my-2 mx-1 description_box">
            <div dangerouslySetInnerHTML={{ __html: getApi.member_text }}></div>
          </div>
          <div className="row member_types">
            <div className="col col-lg-4 col-md-12">
              <Link
                to="/members/MemberExecutive"
                className="nav-link">
                Executive Members
              </Link>
            </div>
            <div className="col col-lg-4 col-md-12">
              <Link to="/members/MemberAffiliate" className="nav-link">
                Affiliate Members
              </Link>
            </div>
            <div className="col col-lg-4 col-md-12">
              <Link to="/members/MemberAssociate" className="nav-link">
                Associate Members
              </Link>
            </div>
            
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default OurMembersHome;
