import React, { useEffect, useState } from "react";
import Loading from "../shared/loading";
import MainBlockLoading from "./../loading_block1"

const AffiliateMember = (props) => {
  const memberId = props.memberId;
  const [memberDetail, setmemberDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const custom_url = window.relativeUrl.split("/");
console.log(custom_url[3]);
  useEffect(() => {
        setIsLoading(true)
        const url = new URL(window.api_url + "/memberDetail?slug=" + custom_url[3]);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setmemberDetail(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [memberId]);

  return (
    <React.Fragment>
      {
        isLoading ? <MainBlockLoading/> : <div className="row affiliate_member description_box">
        <div className="col col-12 border-left">
          <h1 className="my-4"> {memberDetail.name}</h1>

          <div className="ck_editor"
            dangerouslySetInnerHTML={{ __html: memberDetail.long_description }}
          />
          {/* <button className="btn btn-primary my-4 member_btn"><a href={memberDetail.url} rel="noreferrer" target="_blank"> Visit Website <span className="pl-2"><i className="fa-solid fa-angle-right"></i></span></a></button> */}
          <a className="visit_website_a" href={memberDetail.url} rel="noreferrer" target="_blank"> Visit Website <span className="pl-2"><i className="fa-solid fa-angle-right"></i></span></a>
        </div>
        
      </div>
      }
    </React.Fragment>
  );
};

export default AffiliateMember;
