import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/logo.png'
import './header.css'

const Header = (props) => {

  const [UserData, setUserData] = useState([]);
  const [cookies] = useCookies([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = 'Bearer ' + cookies.sanctum_token;


    const url = new URL(window.api_url + "/user");
    fetch(url, {
      method: 'post',
      headers: { 'Authorization': token, 'Content-Type': 'application/json', 'Accept': 'application/json', },

    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data)
        props.getUserData(data);

      });
  }, [])


  const logOutHandler = () => {
    const token = 'Bearer ' + cookies.sanctum_token;
    const url = new URL(window.api_url + "/logout");
    fetch(url, {
      method: 'post',
      headers: { 'Authorization': token, 'Content-Type': 'application/json', 'Accept': 'application/json', },

    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserData()
        window.location = window.baseUrl;
      });
  }

  return (
    <header>
      <nav className="container navbar navbar-expand-lg navbar-light">
        <Link to="/" className="navbar-brand">
          {props.settings.dark_logo === undefined ? <img id="header_logo" src={Logo} alt="logo" className="header_logo" />
            : <img id="header_logo" src={window.api_storage_url + "/images/logo/" + props.settings.dark_logo} alt="ISA Logo " />}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/about/vision" className="nav-link">
                About <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              {/* <Link to="/members/executive/ArcelorMittal-Nippon-Steel-Ltd" className="nav-link">
                Our Members
              </Link> */}
              <Link to="/members" className="nav-link">
                Our Members
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/committees/apexCommittee" className="nav-link">
                Committees
              </Link>
            </li>
            {UserData.name ? <li className="nav-item">
              <Link to="/media/statics" className="nav-link">
                * Statics
              </Link>
            </li> : ""}
            <li className="nav-item">
              <Link to="/media/inTheNews" className="nav-link">
                Media
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/knowledgeBank/insights" className="nav-link">
                Knowledge Bank
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/govtNotifications/ForeignTradePolicy" className="nav-link">
                Govt. Notifications
              </Link>
            </li>

            <li className="nav-item">
              {UserData.name ? <Link to="/" className="nav-link">
                <button className="Extranet-btn mx-2 px-2 py-2" onClick={logOutHandler}>Logout</button>
              </Link> : <Link to="/Extranet" className="nav-link">
                <button className="Extranet-btn mx-2 px-2 py-2">Extranet</button>
              </Link>}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
