import React, { useState } from "react";
import { Link } from "react-router-dom";
import NewsDate from "./date_time/news_date";
import './block_podcast.css';
const Block4 = (props) => {
  const filter_applied = props.block_detail.filter_applied;

  const [d_class, set_d_class] = useState("d-block");
  for (var i = 0; i < filter_applied.length; i++) {
    var name = filter_applied[i];
    if (name.filter_name != props.FilteredData.filter1) {
      // set_d_class('d-none');
    } else {
      console.log(name.filter_name);
      return <Block block_detail={props.block_detail} />;
    }
  }
  if (!props.FilteredData.filter1) {
    // console.log(props);
    return <Block block_detail={props.block_detail} />;
  }
};

const Block = (props) => {
  return (
    <React.Fragment>
      {props.block_detail.url ? (
        props.block_detail.url.toLowerCase().startsWith("http", 0) ? (
          <a href={props.block_detail.url} target="_blank" rel="noreferrer">
            {" "}
            <Detail block_detail={props.block_detail} />{" "}
          </a>
        ) : (
          <Link to={"/media/ourStory/" + props.block_detail.url}>
            {" "}
            <Detail block_detail={props.block_detail} />{" "}
          </Link>
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const Detail = (props) => {
  return (
    <div className="row event_block_row m-3 mb-6 course_container_row">
      <div className="col col-4 text-left steel_technology_img_div">
        <div className="block_img_div">
          <div className="block_img">
            <img
              className="w-100 h-100"
              src={
                window.api_storage_url +
                "/images/uploads/" +
                props.block_detail.image
              }
              alt="course_img"
            />
            <div className="course_arrow_icon"></div>
          </div>
        </div>
      </div>

      <div className="col col-8 text-left course_block_right_div">
        <div className="block_right_div">
          <div className="block_right">
            <h3 className=" pl-3 pt-3"> {props.block_detail.heading} </h3>
            <NewsDate data={props.block_detail} />
            <p className="px-3"><div dangerouslySetInnerHTML={{ __html: props.block_detail.description }} /></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block4;
