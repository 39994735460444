import React, { useEffect, useState } from "react";
import MenulistTabbing from "../left_div/menu_list_tabbing";
import RightMember from "../right_div/member_right";
import MainBlockLoading from "../loading_block1"
import InnerWrapper from "../wrapper_3_9";
import PageHeading from "../page_heding";

const AssociateMembers = () => {
  const custom_url = window.relativeUrl.split("/");
  const [memberId, setmemberId] = useState(custom_url[3]);
  const [menu, setmenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const url = new URL(window.api_url + "/members?member_type=3");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setmenu(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  }, []);

  return (
    <React.Fragment>
      {/* <PageHeading l1="Associate" /> */}

      {
        isLoading ? <MainBlockLoading /> : <InnerWrapper
          col3={<MenulistTabbing submenu={menu} setMemberId={getMemberId} memberId={memberId} />}
          col9={<RightMember memberId={memberId} />}
        />
      }
    </React.Fragment>
  );

  function getMemberId(props) {
    setmemberId(props);
  }
};

export default AssociateMembers;
