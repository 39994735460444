import React, { useEffect, useState } from 'react'
import MainBlock from "../block1"
import MainBlockLoading from "../loading_block1"
import CalenderYear from '../calender_year'
import FilterBlock from '../filter_block'

const about_brochure = () => {
  return (
   <div className='container'>
    <a href={window.api_storage_url+"/documents/Indian-Steell-Association-Brochure-2021.pdf"}> ewr </a>
    <div className='row'>
       <div className='col-lg-3 com-md-12 col-sm-12'>
        <Col3/>
       </div>
       <div className='col-lg-9 col-md-12 col-sm-12'>
        <Col9/>
       </div>
        </div>
   </div>
  )
}


const year = [2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011]
const filter = ["Steel Industry","Steel Association","Import Duties","Raw Material","Industrial Sector","ISA","Budget 2022"]
const Col3 = () => {
    return (
       <>
            <FilterBlock filter={filter} />
            <CalenderYear year ={year} />
            </>
    )
}

const Col9 = () => {
    const [state, setstate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
    const url = new URL(window.api_url+"/brochure");

    
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    };
    
    fetch(url, {
        method: "GET",
        headers,
    })
    .then(response => response.json()).then(data => {
      setstate(data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}, []);
    
    return (
        <React.Fragment>
          {isLoading 
            ? <MainBlockLoading />
            :  <>
            {state.map((e) => (
              <MainBlock block_detail={e} />
            ))}
          </>
            }
        </React.Fragment>
    )
}
export default about_brochure