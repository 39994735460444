import Contact from "./contact";

const MediaContact = () => {
  const contactData = [
    {
      key: 1,
      name: "Alwyn Lobo",
      designation: "Manager - Media and Communication",
      email: "a.lobo@indsteel.org",
      phone: "011 - 42668815",
      twitter: "fgsjgs",
      linkedin: "fgsjgs",
    },
  ];
  return (
    <div className="container">
      <div className="row mt-16">
        {contactData.map((e) => (
          <Contact key={e.key} contactData={e} />
        ))}
      </div>
    </div>
  );
};

export default MediaContact;
